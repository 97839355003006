import React, { ReactNode } from "react";
import "./style.scss";
import "../contact/components/modal/style.scss";
import { IMAGES } from "src/shared/utils/appConstant";
import ContactForm from "./components/modal/contactForm";
import Footer from "../footer";
import { googleMapLink } from "src/shared/utils/string";

interface ContactModalProps {
  children?: ReactNode;
  isOpen: boolean;
  toggle: () => void;
  buttonText?: string;
}
const Contact: React.FC<ContactModalProps> = (props) => {
  return (
    <>
      <div className="contact-mob-container">
        <div className="contact-mob-content">
          <div className="contact-header-name">
            <div>
              <h1 className="contact-text-stroke">Contact Us</h1>
            </div>
            <div className="contact-mob-bg position-abs">
              <img className="position-abs" src={IMAGES.DOT_CIRCLES} alt="dot" />
            </div>
          </div>
          <div className="contact-mob-subheader">
            <p>Transforming Ideas Into Reality</p>
          </div>
          <div className="contact-mob-img">
            <img src={IMAGES.CONTACT_MOB_IMG} alt="contact-img" />
          </div>
          <div className="contact-info contact-info-location">
            <a
              href={googleMapLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={IMAGES.LOCATION_ICON} alt="location" />
            </a>{" "}
            <p>
              B-61, Block B, Sector 67, Eccosphere <br />
              Coworking, Noida
            </p>
          </div>
          <div className="contact-info">
          </div>
          <hr />
          <div className="contact-mob-subheader contact-share-details">
            <p>Share Your Details</p>
            <img className="" src={IMAGES.DOT_CIRCLES} alt="dot" />
          </div>
          <ContactForm />
          <div className="email-link">
            <p style={{paddingLeft: 0, fontSize: '1.5rem', fontWeight: 600, marginTop: '-1rem'}}>Or</p>
            <p>Drop us an Email at <a href="mailto:connect@wingmanpartners.com" target="_blank" rel="noopener noreferrer" className="contact-link">connect@wingmanpartners.com</a></p>
          </div>
        </div>
        <div className="footer-mob">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Contact;