import React from 'react';
import { Link } from 'react-router-dom';
import { cardDataQA, images, scrollToTop, teamQAImages } from 'src/shared/utils/appConstant';
import './style.scss';
import Cards from 'src/components/cards';
import HomePageFooter from '../home/components/Footer';
import Footer from "src/components/footer";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Import the desired effect styles
import { pageRoutes } from 'src/shared/utils/pageRoutes.constant';

const QA = () => {
  return (
    <>
      <div className='page-container-QA'>
        <div className='image-container'>
          <div className="text-container">
            <p className='heading drop-in'>Quality Assurance</p>
            <p className='heading-desc display-none drop-in-2'>A guardian of perfection in all your coding endeavors, our meticulous testing and validation processes ensure flawless software performance, empowering your brand with unwavering reliability.</p>
          </div>
          <p className='breadcrumb position-abs drop-in-2'>
            <Link className='breadcrumb-link' to={pageRoutes.Home} onClick={scrollToTop}>Home </Link>
            <span className="breadcrumb-separator"> / </span>
            <p className="breadcrumb-hover">
              <Link className='breadcrumb-link' to= {pageRoutes.Services}>Services </Link>
            </p>
            <span className="breadcrumb-separator"> / </span>
            <p className="fw-light">
              <Link className='breadcrumb-link' to={pageRoutes.QA} onClick={scrollToTop}>Quality Assurance</Link>
            </p>
          </p>
          <div className='red-bg-box'></div>
        </div>

        <div className="about-our-service">
          <div className="left-section animate__animated animate__fadeInLeft animate__delay-1s">
            <h5>About Our Service</h5>
            <h1>Quality Assurance</h1>
            <h3>About Our Service</h3>
            <p>
              Guardians of digital perfection, our QA experts rigorously test every nuance, ensuring your software not only works but excels in delivering a flawless user experience.
              <img alt="" className='blue-box' src={images.dark_blue_box} />
              <img alt="" className='red-box' src={images.red_box} />
              <div className='red-box-sq'></div>
            </p>
            <img alt="" src={images.blue_box} />
          </div>
          <div className="right-section animate__animated animate__fadeInRight animate__delay-1s">
            <LazyLoadImage
              src={images.QA_IMG}
              alt='QA'
            />
          </div>
          <img src={images.blue_box} alt='...' />
          <img className='circles-QA' src={images.circles} alt='...' />
        </div>

        <div className="lower-div">
          <h1>What We Offer</h1>
          <Cards cardData={cardDataQA} />

          <div className="teams-accreditation">
            <h1>Team’s Accreditation</h1>
            <p>Our team have proven expertise across a range of technologies.</p>
            <div className="images-teams-accreditation">
              {teamQAImages.map((URL, index) => (
                <LazyLoadImage
                src={URL}
                alt='QA-team-accreditation'
              />
              ))}
            </div>
          </div>
        </div>
        <HomePageFooter page="" />
        <Footer />
      </div>

    </>
  )
}

export default QA