import React from "react";
import "./style.scss";
import "bootstrap/dist/css/bootstrap.css";
import Footer from "src/components/footer";
import TeamCard from "./components/Hovercard";
import { teamCardData } from "src/shared/utils/string";
import { Link } from "react-router-dom";
import { scrollToTop } from "src/shared/utils/appConstant";
import { pageRoutes } from "src/shared/utils/pageRoutes.constant";
const Team = () => {
  return (
    <div className="team-container">
      <div className="team-image-container image-container position-rel">
        <div className="text-container">
          <p className="heading drop-in">Our Team</p>
          <p className="heading-desc display-block display-none drop-in-2">
            At Wingman Partners, we're more than just a business; we're a team
            of passionate individuals who believe in the power of dreams and innovation.
            We're here to be your trusted partner in your journey to success.
          </p>
        </div>
        <div className="breadcrumb position-abs drop-in-2">
          <Link to={pageRoutes.Home} onClick={scrollToTop} >Home</Link>
          <span className="breadcrumb-separator"> / </span>
          <p className="fw-light">
            <Link to={pageRoutes.Team}>Our Team</Link>
          </p>
        </div>
        <div className="red-bg-box"></div>
      </div>
      <div className="meet-our-team">
        <div className="team-header">
          <p>Meet Our Team</p>
        </div>
        <div className="card-main-container">
          {teamCardData.map((card, index) => (
            <div className="team-cards drop-in-3" key={index}>
              <TeamCard
                index={index}
                frontImage={card.frontImage}
                backImage={card.backImage}
              />
              <div className="team-card-content">
                <p className="team-card-name">{card.name}</p>
                <p className="team-card-desg">{card.desg}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Team;
