import React from 'react';
import './style.scss';
import CardsServices from 'src/views/SoftwareEngineering/components/CardsServices';
import { cardDataCloudInfra } from 'src/shared/utils/string';
import FooterServices from 'src/views/SoftwareEngineering/components/FooterServices';
import Footer from 'src/components/footer';
import BreadcrumbDevOps from '../BreadcrumbDevOps';
import { Link } from 'react-router-dom';

const CloudInfra = () => {
  return (
    <div className="page-container-cloudinfra">
        <div className='image-container'>
          <div className='text-container drop-in-2'>
          <p className='heading drop-in'>Cloud Infrastructure Consulting </p>
            <p className='heading-desc display-none drop-in-2 drop-in-3'>Unlocking the full potential of cloud technology with expert guidance in infrastructure design, cost-efficiency, and security.</p>
          </div>
            <Link to="/cloud-infra" className='no-decoration '><BreadcrumbDevOps text={'Cloud Infrastructure Consulting'} /></Link>
            <div className='red-bg-box'></div>
        </div>

        <CardsServices cardDataServices={cardDataCloudInfra} />
        <FooterServices />
        <Footer />
    </div>
  )
}

export default CloudInfra