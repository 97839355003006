/* eslint-disable no-useless-concat */
// home page consts
import { IMAGES } from "./appConstant";
import {images} from "./appConstant";
import { pageRoutes } from "./pageRoutes.constant";

export const homeData = [
  {
    heading: "Your Trusted Ally in the realm of IT and Software services",
    desc:
      "We are experts at enabling companies to use cutting-edge solutions to strengthen their infrastructure which aligns with their goals and objectives."
  },

  {
    heading: "Modernizing Business by Embracing Cloud Technology",
    desc:
      "We are a seasoned and dynamic tech-consulting organization empowering businesses to modernize their processes through technology, cloud computing and analytics."
  },

  {
    heading: "Unlocking Growth Opportunities",
    desc:
      "We take pride in ourselves for our end-to-end approach that covers everything from development to cloud management, extending beyond conventional boundaries."
  }
];

// Services consts
export const dataServices = {
  heading: "Our Services At A Glance",
  subHeading: "Thrive in the digital landscape with Wingman Partners: Your strategic ally for offering cutting edge resilient infrastructure. We assist our clients across the following core pillars but not limited to them.",
  buttonContent: "Explore Our Services",
}


// Case Study consts
export const carouselHeading = [
  "Network Management",
  "IoT Security Solutions",
  "Hospitality",
  "Healthcare",
  "Transportation",
  "Tech-Enabled Spaces",
  "Employment and Community Engagement",
  "Kitchen and Dining",
  "Non-Governmental Organization"
];

// Testimonials headings
export const headingTestimonals =
  "We Have Made Impact In Different Industries Across The Globe";
export const partnerStat = [
  "Wingman is a great business partner, providing quality development resources, critical counsel, and excellent professional support to augment high-performing development teams, and highly recommended.",
  "Wingman is a reliable and competent partner who represents their clients well and allows for delegation of important development and deployment tasks with high-quality code.",
  "Wingman Partners is a reliable, communicative, quality-focused, proactive, and creative development partner that is knowledgeable, adaptable,and knows when to push back and ask questions for added value.",
  "The team at this company provided superb attention, delivering a proposal above and beyond expectations in just a few days, making them the perfect choice as web guys for the future.",
];
export const partnerInfo = [
  { name: "Eric Clelland", desg: "COBO" },
  { name: "Serge Lachapelle", desg: " Software Engineering Manager at InVue" },
  { name: " Rex Pugh ", desg: "COBO" },
  { name: " Kapil Gupta", desg: "Warrantee Wise" }

];

// Blogs headings
export const headingBlogs = "Latest Updates";

//Home Page Footer Headings
export const heading =
  "Got questions? We are all Ears and Ready to Click with You!";
export const headingDesc =
  "Whether it's questions, comments, or just a friendly hello, our virtual door is always open for you!";

// Footer
export const footerData = [
  { heading: "Who We Are", headingURL: null, colData: ["About Us", "Leadership", "Team"], routeLink: [pageRoutes.AboutUs, pageRoutes.Leadership, pageRoutes.Team] },
  { heading: "Services", headingURL: null, colData: ["Software Engineering", "Cloud & DevOps", "Quality Assurance", "Operations", "UI & UX"], routeLink: [pageRoutes.SoftwareEngineering, pageRoutes.DevOps, pageRoutes.QA, pageRoutes.Operations, pageRoutes.Designing] },
  { heading: "Company", headingURL: null, colData: ["Blogs", "Success Stories", "Life At Wingman", "Awards"], routeLink: [pageRoutes.Blog, pageRoutes.SuccessStories, pageRoutes.LifeAtWmp, pageRoutes.Awards] },
  { heading: "Solutions", headingURL: pageRoutes.Solutions, colData: ["CXOTulz", "QuFree", "Trakwa"], routeLink: [pageRoutes.CXOTulz, pageRoutes.Qufree, pageRoutes.Trakwa]}
]

// Services Card data

//WebAppDev
export const cardDataWebAppDev = [
  { heading: "Requirements Analysis", subPoints: ["Understanding client needs and business objectives.", "Defining features and functionalities for the web application."] },
  { heading: "UI/UX Design", subPoints: ["Creating an intuitive and visually appealing user interface.", "Focusing on user experience to enhance satisfaction and engagement."] },
  { heading: "Design and Architecture", subPoints: ["Creating a design document which includes High Level and Low Level of Architecture"] },
  { heading: "Front-end Development", subPoints: ["Implementing the designed interface using the latest component-based technologies(e.g., React Js, Angular, Vue Js).", "Ensuring responsiveness and cross-browser compatibility."] },
  { heading: "Back-end Development", subPoints: ["Building server-side logic and database integration.", "Utilizing frameworks and technologies (e.g., Node.js, Django)."] },
  { heading: "Testing", subPoints: ["Conducting thorough testing, including unit testing and user acceptance testing.", "Identifying and fixing bugs for a robust application."] },
  { heading: "Deployment", subPoints: ["Deploying the web application to a hosting environment.", "Configuring servers and ensuring scalability."] },
  { heading: "Maintenance and Support", subPoints: ["Providing ongoing support for bug fixes and updates.", "Monitoring and optimizing performance."] },
]

//MobileAppDev
export const cardDataMobileAppDev = [
  { heading: "Platform Selection", subPoints: ["Choosing between native, hybrid, or cross-platform development.", "Considering iOS, Android, or both platforms."] },
  { heading: "UI/UX Design For Mobile", subPoints: ["Designing mobile-friendly interfaces for a seamless user experience.", "Adhering to platform-specific design guidelines."] },
  { heading: "App Store Submission", subPoints: ["Preparing and submitting the app to respective app stores.", "Complying with store guidelines and regulations."] },
  { heading: "Testing and Debugging", subPoints: ["Rigorous Testing On Different Devices And OS Versions.", "Debugging and resolving platform-specific issues."] },
  { heading: "App Development", subPoints: ["Implementing Features And Functionalities Using Suitable Frameworks.", "Ensuring Optimal Performance And Responsiveness.", "Implement Crashlytics Or Similar Tools For Checking Production env Errors Or Logs."] },
  { heading: "Post-Launch Support", subPoints: ["Monitoring user feedback and addressing issues promptly.", "Rolling out updates and enhancements based on user feedback."] }
]

//ProductReEngg
export const productReEngg = [
  
  { heading: "Feature Enhancement", subPoints: ["Aligning the product with changing market demands.","Adding new features or refining existing ones."] },
  { heading: "Reimagination and Redesign", subPoints: ["Revamping the product's design for a modern and competitive edge.", "Enhancing user interfaces and user experiences."] },
  { heading: "Deployment and User Training", subPoints: ["Providing training for users on new features and changes.", "Deploying the re-engineered product to the market."] },
  { heading: "Current State Analysis", subPoints: ["Evaluating the existing product's strengths and weaknesses.", "Identifying areas for improvement and innovation."] },
  { heading: "Testing and Quality Assurance", subPoints: ["Conducting comprehensive testing to ensure the re-engineered product meets quality standards.", "Addressing any issues identified during testing."] },
  { heading: "Technology Upgrade", subPoints: ["Updating underlying technologies and frameworks with backward compatibility and data retention.", "Improving scalability, security, and performance."] },

]

//Software Integration 
export const softwareIntegration = [
  { heading: "Integration Planning", subPoints: ["Identifying systems or applications to be integrated.", "Defining integration goals and requirements."] },
  { heading: "API Integration", subPoints: [" Utilizing APIs to connect different software systems.", "Ensuring seamless data flow and communication."] },
  { heading: "Middleware Integration", subPoints: ["Implementing middleware solutions for complex integrations.", "Streamlining communication between disparate systems"] },
  { heading: "Data Synchronization", subPoints: ["Ensuring data consistency across integrated systems.", " Implementing real-time or batch data synchronization."] },
  { heading: "Testing Integration Points", subPoints: ["Addressing compatibility and data integrity issues.", "Rigorous testing of integration points."] },
  { heading: "Monitoring and Maintenance", subPoints: ["Implementing monitoring tools for ongoing system integration.", "Providing support for any issues arising from the integrated systems."] },
]

//POC
export const POC_CONTENT = [
  { heading: "Project Scoping", subPoints: ["Collaborating with clients to define the scope and objectives of the POC.", "Understanding specific challenges or requirements that the POC aims to address."] },
  { heading: "Technology Evaluation", subPoints: ["Selecting the appropriate technologies and frameworks for the POC.", "Assessing the feasibility of implementing the desired solution."] },
  { heading: "Documentation", subPoints: ["Documenting the POC process, methodology, and outcomes.", "Creating a comprehensive report highlighting findings and recommendations."] },
  { heading: "Feasibility Analysis", subPoints: ["Conducting a comprehensive analysis to assess the viability of the proposed solution.", "Identifying potential technical or business challenges."] },
  { heading: "Data Integration", subPoints: ["Integrating relevant data sources or mock data for realistic testing.", "Ensuring data compatibility and accuracy."] },
  { heading: "Proof of Value", subPoints: ["Demonstrating the value proposition of the proposed solution.", "Highlighting how the POC addresses specific pain points or business needs."] },
  { heading: "Scalability Assessment", subPoints: ["Evaluating the scalability potential of the solution.", "Understanding how the solution can handle increased loads or data volumes."] },
  { heading: "Performance Metrics", subPoints: ["Establishing key performance indicators (KPIs) for the POC.", "Measuring and analyzing performance against predefined benchmarks."] },
  { heading: "Risk Analysis", subPoints: ["Identifying and mitigating potential risks associated with the proposed solution.", "Providing insights into risk management strategies."] },
  { heading: "Cost Estimate", subPoints: ["Offering a detailed cost estimate for scaling the solution beyond the POC.", "Providing transparency on investment requirements."] },
  { heading: "Client Collaboration", subPoints: ["Engaging clients in regular feedback sessions.", "Iterating on the prototype based on client input."] },
  { heading: "Rapid Prototyping", subPoints: ["Demonstrating key functionalities and features.", "Creating a rapid prototype to visualize the proposed solution."] },

]

//Cloud Infra 
export const cardDataCloudInfra = [
  { heading: "Review, Assessment and Planning", subPoints: ["Infrastructure Assessment", "Business Objectives", "Strategic Roadmap"] },
  { heading: "Architecture Update", subPoints: ["Architectural Enhancement", "Scalability & Performance", "High Availability", "Disaster Recovery"] },
  { heading: "DevOps Consulting Services", subPoints: ["Toolchain Selection", "Best Practices Implementation"] },
  { heading: "Performance Optimization", subPoints: ["Performance Monitoring", "Bottleneck Identification", "Resource Optimization"] }
]

export const cardDataCloudMigration = [
  { heading: "Lift-And-Shift", subPoints: ["Re-Hosting", "Re-Platforming", "Data Migration"] },
  { heading: "Multi-Cloud and Hybrid Cloud", subPoints: ["Multi-Cloud Strategies", "Hybrid Cloud Solutions", "Air-Gapped Solutions"] },
  { heading: "Architecture Update", subHeading: "Application Modernization", subPoints: ["Upgrading legacy applications ", "Leveraging cloud-native technologies for enhanced functionality and agility. "] },
  { heading: "Application Modernization", subPoints: ["Reimagining and upgrading legacy application", "Leveraging cloud-native technologies", "Enhancing user experience and functionality", "Containerization and microservices adoption"] },
  { heading: "Server  To  Serverless", subPoints: ["Transitioning from traditional server-based applications to serverless computing", "Eliminating the need for server provisioning and management", "Integration with serverless platforms"] },
]

export const cardDataDevopsAuto = [
  { heading: "DevOps Containerization Services", subPoints: ["Docker Containers", "Container Orchestration"] },
  { heading: "DevOps CI/CD Services", subPoints: ["Continuous Integration & Deployment", "Continuous Testing"] },
  { heading: "Infrastructure as Code (IaC)", subPoints: ["Wingman Partners is a leader in advancing DevOps Automation through state-of-the-art Infrastructure as Code (IaC) solutions. Our expertise lies in seamlessly managing resources to accelerate software development and deployment. With our innovative approach, we empower you to automate every aspect of infrastructure provisioning, configuration, and scaling, fostering a robust and agile development environment. "] },
  { heading: "Configure Automated Alerts", subPoints: ["Wingman Partners excels in DevOps Automation, offering services for configuring automated alerts. Our expert engineering team ensures real-time monitoring, proactive notifications, and efficient incident response. We optimize performance to enhance overall system reliability, providing a seamless and innovative DevOps experience."] },
  { heading: "Centralized Log Management", subPoints: ["Wingman Partners is at the forefront of DevOps Automation, specializing in centralized log management. Our seasoned engineers leverage cutting-edge solutions to empower you in efficiently collecting, analyzing, and visualizing logs from various sources. This not only facilitates streamlined troubleshooting but also enhances security and system performance, ensuring seamless operations. "] },
]

export const cardDataDevopsOperations = [
  {heading: "DevOps Release Management", subPoints: ["Wingman Partners excels in DevOps Operations by providing comprehensive solutions for Release Management. We empower teams to streamline and automate the end-to-end release process, ensuring faster, more reliable deployments with improved visibility and collaboration across development and operations for enhanced software delivery. "]},
  {heading: "Infrastructure Support & Maintenance Services", subPoints: ["Comprehensive Server Health Reports","Proactive Measures Based On Monitoring Trends"]},
]

export const cardDataSecurity = [
  { heading: "Cost Optimization", subPoints: [`Cost Management & Cost Reduction using in house Tool <a href="https://cxotulz.com/" target=_blank>CXOTulz</a>`, "Cost Reduction Strategies", "Budget Management"] ,},
  { heading: "Security & Compliance", subPoints: ["AWS Hardening",  "Code Analysis", "Code & Data security assessment",`Cloud security analyses using in house Tool house Tool <a href="https://cxotulz.com/" target=_blank>CXOTulz</a>`, "Vulnerability Assessment & Management"] },
]

export const cardDataTestingAutomation = [
  { heading: 'Automated Test Frameworks', subPoints: ['Choosing The Right Framework', 'Implementation Strategies', 'Benefits & Challenges'] },
  { heading: 'Test Case Design', subPoints: ['Best Practices For Effective Test Cases', 'Ensuring Comprehensive Test Coverage', ] },
  { heading: 'Continuous Integration and Testing', subPoints: ['Integration with CI/CD Pipelines', 'Automated Builds & Testing', 'Monitoring and Reporting'] },
  { heading: 'Regression Testing', subPoints: ['Strategies For Efficient Regression Testing', 'Automation For Regression Test Suites', 'Managing Changes & Impact Analysis'] },
]

export const cardDataAPIDrivenTesting = [
  { heading: 'API Test Planning', subPoints: ['Identifying Test Scenarios', 'Creating Test Data For API Endpoints', 'Test Environment Considerations'] },
  { heading: 'Security In API Testing', subPoints: ['Common Security Concerns In APIs', 'Best Practices For API Security Testing', 'Integrating Security Checks In API Tests'] },
  { heading: 'API Test Scripting', subPoints: ['Writing Effective Test Scripts', 'Handling Authentication & Authorization', 'Parameterization and Data-Driven Testing'] },
  { heading: 'Automation Tools For API Testing', subPoints: ['Popular API Testing Tools', 'Criteria For Tool Selection', 'Integration With Development and CI/CD Processes'] },
]

export const cardDataPerformanceTesting = [
  { heading: 'Reporting and Documentation', subPoints: ['Generating Comprehensive Reports', 'Documentation Of Test Results', 'Communicating Findings To Stakeholders'] },
  { heading: 'Performance Test Planning', subPoints: ['Defining Performance Metrics', 'Establishing Performance Baselines', 'Identifying Performance Goals'] },
  { heading: 'Performance Test Tools', subPoints: ['Popular Performance Testing Tools', 'Selection Criteria', 'Customization and Integration'] },
  { heading: 'Execution and Monitoring', subPoints: ['Running Performance Tests', 'Monitoring System Resources', 'Analyzing Test Results In Real-Time'] },
  { heading: 'Performance Test Environment Setup', subPoints: ['Configuring Test Environments', 'Hardware & Software Considerations', 'Emulating Real-World Scenarios'] },

]

export const cardDataPenetrationTesting = [
  { heading: 'Network Penetration Testing Tools', subPoints: ['Nmap For Network Scanning', 'Wireshark For Packet Analysis', 'Burp Suite For Web Application Scanning', 'Nessus For Identifying Network Security Issues', 'Nexpose For Risk Assessment & Prioritization'] },
  { heading: 'Common Challenges & Considerations', subPoints: ['Addressing Ethical and Legal Concerns', 'Continuous Testing For Evolving Threats', 'Balancing Thorough Testing With Business Continuity'] },
  { heading: 'Role Of Penetration Testing In The SDLC', subPoints: ['Ensuring Secure Coding Practices','Integration Security Testing Into The Software Development Lifecycle'] },
  { heading: 'Benefits and Outcomes', subPoints: ['Enhancing Overall Cybersecurity Posture.', 'Providing Actionable Insights For Risk Mitigation'] },
]

export const cardDataWebDesigning = [
  { heading: 'User Centric Design', subPoints: ['Web interfaces that prioritize user needs.', 'Personalized interfaces for user delight', 'Streamlined workflows boost efficiency'] },
  { heading: 'Responsive Design', subPoints: ['Adaptable layouts for various devices.', 'Scalable design for evolving needs.', 'Fostering consistent UX across devices.'] },
  { heading: 'Intuitive Navigation', subPoints: ['Clear cues for effortless understanding.', 'Seamless user journeys for ease.', 'Minimalistic elements for clarity.'] },
]

export const cardDataAppDesigning = [
  { heading: 'Engaging Mobile Experiences', subPoints: ['Interactive UI elements for engagement.', 'Delightful micro-interactions.', 'Personalized user journeys.'] },
  { heading: 'Performance Optimization', subPoints: ['Seamless navigation for better UX.', 'Responsive layouts.', 'Iterative Prototyping Refinement.'] },
  { heading: 'User-Driven Improvements', subPoints: ['Feedback Collection Channels.', 'Iterative Updates from Reviews.', 'Regular User Engagement Analysis.'] },
]

export const cardDataInfoArchitecutreDesign = [
  { heading: 'User Flow Mapping', subPoints: ['Initial Exploration Pathways.', 'Seamless Onboarding Routes.', 'Error Resolution Pathways.'] },
  { heading: 'Content Hierarchy', subPoints: ['Clear Information Prioritization.', 'Visual Hierarchy for Emphasis.', 'Consistent Hierarchy Across Pages.'] },
  { heading: 'Wireframing and Prototyping', subPoints: ['Conceptual Wireframes for Ideation.', 'Interactive Prototypes for Testing.', 'Visual Design Alignment.'] },
]

// Solutions card
export const cardDataSolutions = [
  { image: images.costAnalysis, heading: "CXO's Cost Analysis", subPoints: ["Elevate financial oversight with CXOTulz's panoramic view of cloud spending.", "Forecast future costs accurately using advanced analytics. "] },
  { image: images.Vector, heading: "CXO's Tech Team", subPoints: ["Enjoy dedicated support from CXOTulz's Tech Team for continuous assistance. ", "Focus on product development with peace of mind, knowing that your cloud environment is in capable hands."] },
  { image: images.loginvector, heading: "Single login for all Cloud  & Accounts", subPoints: ["Experience the convenience of a single login for all your cloud accounts with CXOTulz.", "Say goodbye to juggling multiple logins and streamline your authentication processes for effortless access."] },
  { image: images.cloudSwitch, heading: "CXO's Multi-Cloud View", subPoints: ["CXOTulz seamlessly integrates data from multiple cloud platforms, supporting organizations that leverage multi-cloud environments for their operations. ", "CXOTulz ensures comprehensive data collection and analysis, enabling informed decision-making across all cloud environments. "] }
]

export const cardSolution = [
  { image: images.cxotulzLogo, desc: "Unlocking the power of modernized data management, CXO Tulz reshapes the narrative of efficiency and innovation in business.", explore: images.EXPLORE_1, routeLink: pageRoutes.CXOTulz},
  { image: images.Qufree, desc: "Your ultimate stop for seamless physical shopping solutions. Effortless browsing and hassle-free transactions redefine convenience at every step.", explore: images.EXPLORE_2, routeLink: pageRoutes.Qufree},
  { image: images.Trakwa, desc: "Effortlessly centralize documents, set timely reminders, and enjoy seamless accessibility for unmatched organizational efficiency.", explore: images.EXPLORE_3, routeLink: pageRoutes.Trakwa}
]

export const cardSolutions = [
  { card:'1.', heading: "CXO Group Members", subPoints: ["Gain rapid access to crucial cloud cost and security data, facilitating informed decision-making."] },
  { card:'2.', heading: "Cloud Administrators", subPoints: ["Simplify cloud resource management, ensuring cost-effectiveness and security measures are upheld. "] },
  { card:'3.', heading: "IT Managers", subPoints: ["Enhance IT infrastructure operations with actionable insights, reducing risks and maximizing efficiency."] },
  { card:'4.', heading: "Data Analysts", subPoints: ["Extract valuable insights from cloud data to drive strategic resource allocation and decision-making."] },
  { card:'5.', heading: "Cloud Consultants", subPoints: ["Seamlessly implement and execute cloud management strategies to optimize organizational performance. "] },
  { card:'6.', heading: "Enterprise Users", subPoints: ["Streamline operations, minimize costs, and fortify security measures to drive organizational growth."] },

]
//Trakwa card
export const cardDataTrakwa = [
  { card:'1.', heading: "Seamless Cloud Accessibility:", subPoints: ["Trakwa offers the convenience of accessing your crucial documents from the cloud, anytime and anywhere. No more frantic searches – your documents are securely stored and instantly accessible whenever the need arises."] },
  { card:'2.', heading: "Smart Document Reminders:", subPoints: ["Never miss a deadline or forget to renew a warranty again. Trakwa allows users to customize Document Reminders, ensuring you receive timely alerts for warranty expirations and other important deadlines."] },
  { card:'3.', heading: "Centralized Product Details Hub:", subPoints: ["Manage all your product details in one centralized hub. Trakwa empowers you to input and organize essential information about your products, simplifying the tracking of warranties, purchase dates, and other relevant details."] },
  { card:'4.', heading: "Effortless Categorization of Product Invoices:", subPoints: ["Categorize your Product Invoices effortlessly based on their respective categories. Whether it's electronics, appliances, or furniture, Trakwa helps you keep your purchase records organized for easy retrieval."] },
  { card:'5.', heading: "Tailored Alert Notifications:", subPoints: ["Receive personalized alert notifications during your warranty period. Trakwa ensures you stay informed about upcoming expirations, giving you ample time to take necessary actions."] },
]

export const cardTrakwa = [
  { card:'Step 1', heading: "Sign Up:", subPoints: ["Create your Trakwa account to embark on a journey to organized document and product management. "] },
  { card:'Step 2', heading: "Add an Item:", subPoints: ["Quickly add your products to the platform, providing key details for efficient tracking."] },
  { card:'Step 3', heading: "Upload Purchase Proof:", subPoints: ["Capture purchase proofs with your phone and seamlessly upload them to Trakwa. It's that simple."] },
]

export const trakwaLastCard = [
  { image: images.TRAKWA3, heading: "UI and UX Excellence:", subPoints: ["Our user interface and user experience design ensure a seamless and enjoyable interaction with the Trakwa platform."] },
  { image: images.TRAKWA2, heading: "React Native Expertise:", subPoints: ["Trakwa utilizes the power of React Native to deliver a responsive and cross-platform mobile experience."] },
  { image: images.TRAKWA1, heading: "NodeJs Reliability:", subPoints: ["Our backend development with NodeJs ensures robust and efficient data processing, providing you with a reliable and fast service."] }
]

//QuFree cards
export const cardDataQufree = [
  { card:'1.', heading: "Express Ordering:", subPoints: ["Say goodbye to delays due to queues. We ensure quick and efficient ordering, eliminating the need to wait in long queues or endure delays from shops and eateries around you. "] },
  { card:'2.', heading: "Password-Less Login:", subPoints: ["Experience hassle-free access with Qufree's password-less login feature. Users can securely log in through OTP SMS, enhancing both convenience and security."] },
  { card:'3.', heading: "Efficient Cart Management:", subPoints: ["The cart icon allows users to seamlessly manage items in their cart. Easily review, add, or remove items, ensuring a smooth and efficient ordering process."] },
  { card:'4.', heading: "Seamless Registration Process:", subPoints: ["It’s a user-friendly sign-up process ensures a smooth onboarding experience. New users can effortlessly create an account using a simple registration form, making it quick and easy to join the QuFree community."] },
  { card:'5.', heading: "Popular Categories Showcase:", subPoints: ["Explore trending categories with clickable circular tiles under the Popular Categories section. This engaging feature highlights products in an appealing way, allowing users to discover and select their preferred options effortlessly."] },
  { card:'6.', heading: "Comprehensive Order History:", subPoints: ["Keep track of your purchases with the comprehensive order history feature. Review all previous orders, whether successful or canceled, in a chronological format, making it convenient to revisit your favorite places. "] },
  { card:'7.', heading: "Intelligent Search Filters:", subPoints: ["QuFree's intelligent search filters allow users to search for vendors, products, or categories effortlessly. Results are displayed in a clear and organized format, with card-formatted information for easy navigation. "] },
  { card:'8.', heading: "Inclusive User Base:", subPoints: ["QuFree caters to everyone who wants to see what the nearby businesses around them have got to offer. It's a versatile app designed to meet the needs of consumers seeking rapid satisfaction."] },
  { card:'9.', heading: "Time-Saving Benefits:", subPoints: ["Time is money, and we are here to save your money. QuFree ensures a multitude of choices to swiftly satisfy your need at the moment without standing in a queue, waiting for your order to be prepared."] },
]

export const qufreeCard = [
  { pin:images.QU1, image: images.QU_REGISTER, heading: "Join now:", subPoints: ['Click on "Join Now" to start the registration process.'] },
  { pin:images.QU2, image: images.QU_SIGNUP, heading: "Registration Form:", subPoints: ["Fill in your Name, Email, and Phone Number in the registration form. Ensure uniqueness for each account (email and phone)."] },
  { pin:images.QU3, image: images.QU_PHONE, heading: "OTP Verification:", subPoints: ['Enter the correct OTP received. Click "Create Account."'] },
  { pin:images.QU4, image: images.CSTM_DASH, heading: "Dashboard:", subPoints: [`Your account is created successfully, and you're redirected to the Dashboard. A modal will confirm "Account Successfully Created."`] }

]

export const cardDataQufre = [
  { card:'1.', heading: "Effortless Onboarding:", subPoints: ["Streamlined registration with a single Mobile Number input ensures quick setup for vendors without the hassle of multiple fields. "] },
  { card:'2.', heading: "Seamless Transactions:", subPoints: ["Qufree facilitates direct payments to your account, ensuring effortless payments for vendors. Focus on your expertise of delivering the best, while we take care of the payment process."] },
  { card:'3.', heading: "Real-time Notifications:", subPoints: ["Receive instant alerts for orders and other actions, keeping vendors informed about the status of their orders and actions that need to be performed.  "] },
  { card:'4.', heading: "Profile Management Made Simple:", subPoints: ["Easy addition of essential details on the Profile Page allows vendors to effortlessly provide key information, including store details, enhancing their online presence. "] },
  { card:'5.', heading: "Business Performance Insights: ", subPoints: ["Gain valuable insights into your business with Qufree's comprehensive revenue tracking, empowering you to make informed, strategic decisions for business growth. "] },
  { card:'6.', heading: "Optimized Inventory Management: ", subPoints: ["Qufree provides a user-friendly interface for vendors to efficiently manage their stock levels, allowing for real-time oversight and optimization of supply chains. "] },
 ]

 export const cardVendor = [
  { pin:images.QU1, image: images.QU_REGISTER, heading: "Join now:", subPoints: ['Click "Join Now" to start. Enter a valid phone number to proceed.'] },
  { pin:images.QU2, image: images.QU_PHONE, heading: "OTP Verification:", subPoints: ['Enter the correct OTP received. Click "Create Account."'] },
  { pin:images.QU3, image: images.PROFILE_QU, heading: "Profile Page:", subPoints: ['Add Store Name and some optional details and click "Save" – Account created!.'] },
  { pin:images.QU4, image: images.ACC_CREATED, heading: "Dashboard:", subPoints: [`Your account is created successfully, and you're redirected to the Dashboard. A modal will confirm "Account Successfully Created."`] }

]
// Operations Cards

export const cardDataTechnicalWriting = [
  { heading: 'Precision in Document Crafting', subPoints: ['Craft technical documents with precision adhering to industry standards for clarity and consistency.'] },
  { heading: 'Strategic Use of Diagrams', subPoints: ['Employ strategic use of diagrams to enhance understanding, making technical concepts more accessible and user-friendly.'] },
  { heading: 'Versioning and Revision Control', subPoints: ['Ensure document precision through meticulous versioning and revision control, maintaining accuracy and relevance throughout the content\'s lifecycle.'] },
  { heading: 'Expertise in API/UI/SDK Documentation', subPoints: ['Specialized proficiency in creating comprehensive API, UI, and SDK documentation, complete with a glossary for enhanced comprehension.'] },
  { heading: 'Commitment to Accessibility, Compliance, and Knowledge Base', subPoints: ['Dedication to ensuring documents are accessible, compliant with relevant standards, and contribute to the development of a comprehensive knowledge base.'] },
  { heading: 'Continuous Improvement through Collaborative Content Management', subPoints: ['Foster continuous improvement by utilizing collaborative content management strategies, allowing for the evolution and refinement of technical documentation over time.'] },
]

export const projectManagement = [
  { heading: 'Resource Management, Budgeting, and Cost Management', subPoints: ['Optimize resource allocation, manage budgets, and control costs to deliver high-quality website services within defined financial parameters.'] },
  { heading: 'Monitoring and Generation of Various Project Related Reports', subPoints: ['Implement monitoring mechanisms to track project progress. Generate various reports to provide insights and transparency in project-related activities.'] },
  { heading: 'Communication Management & Stakeholder Management', subPoints: ['Establish effective communication channels and manage stakeholders to ensure alignment with project goals and successful collaboration.'] },
  { heading: 'Quality Management & Change Management', subPoints: ['Implement rigorous quality management processes to deliver high-standard website services. Proactively handle changes through effective change management practices.'] },
  { heading: 'Risk Management', subPoints: ['Identify, assess, and mitigate potential risks associated with website projects to ensure smooth execution and minimize disruptions.'] },
  { heading: 'Closure and Evaluation', subPoints: ['Conduct a thorough project closure, including the evaluation of project outcomes. Document lessons learned and facilitated a seamless transition for future projects.'] },
]

export const productManagement = [
  { heading: 'Market Research & Product Planning', subPoints: ['Utilize Gantt charts, Work Breakdown Structure (WBS), Critical Path Method (CPM), and Agile Methodology to meticulously plan and schedule projects.'] },
  { heading: 'Prioritization & Development Oversight', subPoints: ['Prioritize features strategically and provide vigilant oversight during development, ensuring alignment with product goals and objectives for successful outcomes.'] },
  { heading: 'Cross-Functional Collaboration', subPoints: ['Promote cross-functional collaboration for streamlined product development, fostering open communication, knowledge sharing, and a culture of synergy and creativity to ensure successful outcomes.'] },
  { heading: 'User Experience (UX) Design', subPoints: ['Focus on creating a positive user experience by employing effective design principles and user-centric approaches, enhancing the overall product usability and satisfaction.'] },
  { heading: 'Go-to-Market Strategy', subPoints: ['Develop a comprehensive go-to-market strategy to facilitate successful product launches and market penetration. Tailor approaches to effectively reach and engage target audiences, ensuring product visibility and adoption.'] },
  { heading: 'End-of-Life Planning', subPoints: ['Strategically plan for the product\'s end-of-life, considering market trends and user needs. Develop a structured approach to gracefully retire the product, minimizing disruption and ensuring a smooth transition for users and stakeholders.'] },
  { heading: 'Feedback Analysis & Performance Monitoring', subPoints: ['Analyze user feedback and continuously monitor product performance, making data-driven improvements to enhance functionality, user satisfaction, and overall success in the market.'] },
]

export const support = [
  { heading: '24x7 Specialized Monitoring', subPoints: ['Implement specialized monitoring 24x7 to promptly detect and address potential issues, ensuring the stability and reliability of systems.'] },
  { heading: 'Real-Time Issue Resolution', subPoints: ['Resolve issues in real time utilizing advanced diagnostics for high-level technical problem resolution, minimizing downtime and optimizing system performance.'] },
  { heading: 'Proactive Incident Handling', subPoints: ['Proactively handle incidents with adherence to Service Level Agreements (SLAs), ensuring swift and efficient resolution while maintaining high service standards.'] },
  { heading: 'Escalation Management', subPoints: ['Manage escalations for all types of incidents, ensuring a structured approach to problem resolution and timely involvement of relevant stakeholders.'] },
  { heading: 'Continuous Improvement', subPoints: ['Foster continuous improvement through knowledge transfer, enabling the team to stay abreast of the latest technologies and industry best practices for enhanced support services.'] },
  { heading: 'Performance Tuning and Capacity Planning', subPoints: ['Conduct performance tuning and capacity planning to optimize system efficiency and ensure resources meet current and future demands.'] },
  { heading: 'Automation for Issue Resolution', subPoints: ['Implement automation tools and processes to enhance efficiency in issue resolution, reducing manual intervention and accelerating problem-solving.'] },
  { heading: 'Optimized Resource Management', subPoints: ['Ensure high availability and minimal attrition by adeptly balancing workloads, optimizing resource allocation, and fostering a supportive work environment, promoting stability and continuity within the workforce.'] },
  { heading: 'Compliance Management', subPoints: ['Ensure compliance with industry standards through rigorous monitoring and adherence to regulatory requirements, maintaining a secure and reliable support environment.'] },
  { heading: 'Vendor Collaboration', subPoints: ['Collaborate with vendors for swift problem resolution, leveraging their expertise and resources to address issues effectively and efficiently.'] },

]


//card services data ends

// about us flip card data
export const cardsData = [
  {
    index: 0,
    frontImage: IMAGES.GROUP_IMG,
    frontContent: "Customer Focused Approach",
    backImage: "",
    backHeader: "Customer Focused Approach",
    backContent:
      "We turn your vision into our mission. With strategic designs, we ensure your project's success, keeping you involved every step of the way."
  },
  {
    index: 1,
    frontImage: IMAGES.NO_COMPORMISE,
    frontContent: "No Compromise On Quality",
    backImage: "back1.jpg",
    backHeader: "No Compromise On Quality",
    backContent:
      "We prioritize quality. Our dedicated QA team ensures defect prevention, risk mitigation, and top-notch results in every project phase."
  },
  {
    index: 2,
    frontImage: IMAGES.DEDICATED_RESOURCES,
    frontContent: "Dedicated Resource Allocation",
    backImage: "back1.jpg",
    backHeader: "Dedicated Resource Allocation",
    backContent:
      "We ensure your projects will benefit from our dedicated resource assignments, ensuring focused attention and expertise."
    },
  
  {
    index: 3,
    frontImage: IMAGES.OWNERSHIP,
    frontContent: "Ownership",
    backImage: "back1.jpg",
    backHeader: "Ownership",
    backContent: 
      "We take the lead in crafting solutions that drive success. With dedication and accountability, our team ensures your project goals become ours too."
  },
  {
    index: 4,
    frontImage: IMAGES.TECH_COMPETENCE,
    frontContent: "Technology Competence",
    backImage: "back1.jpg",
    backHeader: "Technology Competence",
    backContent:
      "We wield tech like an electric guitar, shredding challenges with tailored solutions and swift delivery for your market advantage."
  },
  {
    index: 5,
    frontImage: IMAGES.TALENT_GROUP,
    frontContent: "Pool Of Best Talent",
    backImage: "",
    backHeader: "Pool Of Best Talent",
    backContent:
      "We select top engineering talent based on expertise and performance, ensuring your projects are in capable hands for timely delivery."
  },
  
  {
    index: 6,
    frontImage: IMAGES.CENTARLIZED_SUPPORT,
    frontContent: "Centralized Support",
    backImage: "back1.jpg",
    backHeader: "Centralized Support",
    backContent:
      "We offer a singular platform for both existing project support and the development of new products."
  },
  {
    index: 7,
    frontImage: IMAGES.GLOBE_IMG,
    frontContent: "Vast Domain Experience",
    backImage: "back1.jpg",
    backHeader: "Vast Domain Experience",
    backContent:
      "We deliver cost-effective, scalable solutions by staying ahead with continuous research and adopting the latest tech stacks."
  },
  
  {
    index: 8,
    frontImage: IMAGES.REQUIREMENT_SCRUTINY,
    frontContent: "Requirement Scrutiny",
    backImage: "back1.jpg",
    backHeader: "Requirement Scrutiny",
    backContent:
      "We prioritize an in-depth understanding of the development requirements, questioning and clarifying to prevent blind development. This ensures that customers receive a product that aligns closely with their needs."
  },
  {
    index: 9,
    frontImage: IMAGES.COST_OPTIMIZATION,
    frontContent: "Cost Effective",
    backImage: "back1.jpg",
    backHeader: "Cost Effective",
    backContent:
      "We offer the best rates, which are perfect for smaller businesses seeking cost-effective solutions to help you achieve your goals with technology-smart infrastructure."
  },
  {
    index: 10,
    frontImage: IMAGES.TRUSTED_PARTNERSHIP,
    frontContent: "Trusted Partnerships",
    backImage: "back1.jpg",
    backHeader: "Trusted Partnerships",
    backContent:
      "We commit to quality and timely deliveries and have garnered contracts from partners who already engage with other vendors."
  }, 
  {
    index: 11,
    frontImage: IMAGES.FLEXIBLE_PAYMENT,
    frontContent: "Flexible Execution Model",
    backImage: "back1.jpg",
    backHeader: "Flexible Execution Model",
    backContent:
      "We choose the most suitable mode for project management, be it through a Statement of Work (SOW)/ an Annual Contract Basis/Full-Time Engagement Basis/Resource augmentation Basis. Our platform allows for convenient selection."
  }
  
  // Repeat for other cards
];

// Success Stories Strings
export const successStories = {
  heading: 'Success Stories',
  desc: 'From vision to victory: Wingman Partners scripts success stories, uniting aspirations with unwavering dedication in the dynamic digital landscape of innovation.'
}

// my team card data
export const teamCardData = [
  {
    frontImage: IMAGES.ASHAV,
    name: "Ashav Kaushik",
    desg: "CEO",
    backImage: ""
  },
  {
    frontImage: IMAGES.NISHANT,
    backImage: "",
    name: "Nishant Kaushik",
    desg: "VP Engineering"
  },
  {
    frontImage: IMAGES.GARIMA,
    backImage: '',
    name: "Garima Singh",
    desg: "VP,Quality & Operations"
  },
  {
    frontImage: IMAGES.ALOK,
    backImage: "",
    name: "Alok Kumar",
    desg: "VP New ventures"
  },
  {
    frontImage: IMAGES.KAWALDEEP,
    backImage: "",
    name: "Kawaldeep Singh",
    desg: "Lead Engineer"
  },
  {
    frontImage: IMAGES.MUKUL,
    backImage: "",
    name: "Mukul Srivastava",
    desg: "Lead DevOps"
  },
  {
    frontImage: IMAGES.ANKITA,
    backImage: "",
    name: "Ankita Varshney",
    desg: " Lead QA"
  },
  {
    frontImage: IMAGES.MOHIT,
    backImage: "",
    name: "Mohit Yadav",
    desg: "SDE III"
  },
  {
    frontImage: IMAGES.SANDEEP,
    backImage: "",
    name: "Sandeep Jalal",
    desg: "SDE III"
  },
  {
    frontImage: IMAGES.PRAMITA,
    backImage: "",
    name: "Pramita Ghosh ",
    desg: "Senior HR Executive (Finance)"
  },
  {
    frontImage: IMAGES.UNIQUE,
    backImage: "",
    name: "Unique Sharma",
    desg: "Devops III"
  },
  {
    frontImage: IMAGES.AMAN,
    backImage: "",
    name: "Aman Sharma",
    desg: "SDE III"
  },
  {
    frontImage: IMAGES.SHIVANI,
    backImage: "",
    name: "Shivani Tiwari",
    desg: "QA I"
  },
  {
    frontImage: IMAGES.VIKAS,
    backImage: "",
    name: "Vikas Raghav",
    desg: "DevOps I"
  },
  {
    frontImage: IMAGES.NADEEM,
    backImage: "",
    name: "Nadeem Khan",
    desg: "SDE I"
  },
  {
    frontImage: IMAGES.SRISHTI,
    backImage: "",
    name: "Srishti Gupta",
    desg: "SDE I"
  },
  {
    frontImage: IMAGES.AKASH_TYAGI,
    backImage: "",
    name: "Aakash Tyagi",
    desg: "SDE I"
  },
  {
    frontImage: IMAGES.KANISH,
    backImage: "",
    name: "Kanish Tyagi",
    desg: "QA I"
  },
  {
    frontImage: IMAGES.ARVIND,
    backImage: "",
    name: "Arvind Yadav",
    desg: "SDE I"
  },
  {
    frontImage: IMAGES.JAYANT,
    backImage: "",
    name: "Jayant Singh",
    desg: "UI/UX Designer"
  },
  {
    frontImage: IMAGES.ANUBHAV,
    backImage: "",
    name: "Anubhav Bhardwaj",
    desg: "UI/UX Designer"
  },
  {
    frontImage: IMAGES.DIVESH,
    backImage: "",
    name: "Divesh Singh",
    desg: "SDE I"
  },
  {
    frontImage: IMAGES.ANANYA,
    backImage: "",
    name: "Ananya Singh",
    desg: "Project Co-ordinator"
  },
  {
    frontImage: IMAGES.DIVYANSHU,
    backImage: "",
    name: "Divyanshu Kishan",
    desg: "SDE I"
  },
  {
    frontImage: IMAGES.HRITIK,
    backImage: "",
    name: "Hrithik Hiranwar",
    desg: "DevOps I"
  },
  {
    frontImage: IMAGES.MAYANK,
    backImage: "",
    name: "Mayank Tyagi",
    desg: "QA I"
  },
  {
    frontImage: IMAGES.NISHITA,
    backImage: "",
    name: "Nishita Chaudhary",
    desg: "QA I"
  },
  {
    frontImage: IMAGES.SHIVANK,
    backImage: "",
    name: "Shivank Chauhan",
    desg: "SDE I"
  },
  {
    frontImage: IMAGES.AAKASH,
    backImage: "",
    name: "Akash Srivastava",
    desg: "DevOps I"
  },
  {
    frontImage: IMAGES.GAURAV,
    backImage: "",
    name: "Gaurav Rajpoot",
    desg: "SDE I"
  },
  {
    frontImage: IMAGES.YASH,
    backImage: "",
    name: "Yash Chaurasiya",
    desg: "SDE I"
  },
  {
    frontImage: IMAGES.AJAY,
    backImage: "",
    name: "Ajay Rathour",
    desg: "SDE I"
  },
  {
    frontImage: IMAGES.KARNDEEP,
    backImage: "",
    name: "Karandeep Kaur",
    desg: "Sr. HR Executive"
  },
  {
    frontImage: IMAGES.MUSKAN,
    backImage: "",
    name: "Muskan Kumari",
    desg: "SDE I"
  },
  {
    frontImage: IMAGES.YANA,
    backImage: "",
    name: "Yana Rakesh",
    desg: "Project Co-ordinator "
  },
  {
    frontImage: IMAGES.HEMANSHI,
    backImage: "",
    name: "Hemanshi Chaudhary",
    desg: "QA I"
  },
  {
    frontImage: IMAGES.ANKIT,
    backImage: "",
    name: "Ankit Kumar",
    desg: "SDE I"
  },
  {
    frontImage: IMAGES.LOKENDRA,
    backImage: "",
    name: "Lokendra Singh",
    desg: "SDE I"
  },
  {
    frontImage: IMAGES.DAKSH,
    backImage: "",
    name: "Daksh Kotian",
    desg: "DevOps I"
  },
  {
    frontImage: IMAGES.GAURAV_SINGH,
    backImage: "",
    name: "Gaurav Singh",
    desg: "SDE I"
  },
  {
    frontImage: IMAGES.AMAN_GUPTA,
    backImage: "",
    name: "Aman Gupta",
    desg: "SDE I"
  },
  {
    frontImage: IMAGES.SUYASH,
    backImage: "",
    name: "Suyash Vikram Singh",
    desg: "SDE I"
  },
  
  
  
  

];

//Blog page card data
export const cardBlogData = [
  {
    by: "Daksh and Hrithik",
    date: "04 June, 2024",
    URL: "https://medium.com/@besocial_27455/expert-guide-to-cloud-cost-optimization-for-reduced-expenses-8a964e09c7cb",
    UrlDesc:
      "Expert Guide to Cloud Cost Optimization for Reduced Expenses",
    image: IMAGES.BLOG_CLOUD_COST
  },
  {
    by: "Nishant Kaushik",
    date: "02 May, 2024",
    URL: "https://medium.com/@besocial_27455/leveraging-microservices-in-cloud-native-architecture-5bd0a77c5b28",
    UrlDesc:
      "Leveraging Microservices in Cloud-Native Architecture",
    image: IMAGES.NISHANT_CXO_BLOG
  },
  {
    by: "Ankita Varshney ",
    date: "04 March, 2024",
    URL: "https://medium.com/@besocial_27455/the-use-of-chatgpt-for-quality-assurance-de65d59997e1",
    UrlDesc:
      "The Use of ChatGPT for Quality Assurance",
    image: IMAGES.ANKITA_QA_BLOG_IMG
  },
  {
    by: "Ankita Varshney ",
    date: "21 Nov, 2023",
    URL: "https://medium.com/@besocial_27455/empower-manual-testing-with-top-chrome-extensions-ba63b2d0c811",
    UrlDesc:
      "Empower Manual Testing with Top Chrome Extensions",
    image: IMAGES.ANKITA_BLOG_IMG
  },
  {
    by: "Anubhav Bhardwaj",
    date: "27 Oct, 2023",
    URL: "https://medium.com/@besocial_27455/top-ai-platforms-for-ui-ux-and-its-transformative-power-part-2-9829afa514af",
    UrlDesc:
      "Top AI Platforms for UI/UX and its Transformative Power Part-2",
    image: IMAGES.ANUBHAV_BLOG_IMG_2
  },
  {
    by: "Anubhav Bhardwaj",
    date: "20 Oct, 2023",
    URL: "https://medium.com/@besocial_27455/top-ai-platforms-for-ui-ux-and-its-transformative-power-ff2b27b5d5c9",
    UrlDesc:
      "Top AI Platforms for UI/UX and its Transformative Power",
    image: IMAGES.ANUBHAV_BLOG_IMG_1
  },
  {
    by: "Unique Sharma",
    date: "16 Oct, 2023",
    URL: "https://medium.com/@besocial_27455/optimizing-storage-costs-in-amazon-s3-and-ebs-while-ensuring-data-availability-and-performance-655ee83e5e11",
    UrlDesc:
      "Optimizing Storage Costs in Amazon S3 and EBS While Ensuring Data Availability and Performance",
    image: IMAGES.BLOG_CARD_IMG_1
  },
  {
    by: " Mukul Srivastava",
    date: "25 Sept, 2023",
    URL: "https://medium.com/@besocial_27455/ensuring-effortless-project-execution-with-devops-expertise-at-wingman-partners-aa2f8d1d59f5",
    UrlDesc:
      "Ensuring Effortless Project Execution with DevOps Expertise at Wingman Partners",
    image: IMAGES.BLOG_CARD_IMG_2
  },
  {
    by: "Kanish Tyagi",
    date: "15 Sept, 2023",
    URL: "https://medium.com/@besocial_27455/common-quality-assurance-mistakes-and-how-to-avoid-them-a-complete-guide-bd528b5f66b6",
    UrlDesc:
      "Common Quality Assurance Mistakes and How to Avoid Them: A Complete Guide",
    image: IMAGES.BLOG_CARD_IMG_3
  },
  {
    by: "Jayant Singh",
    date: "06 Sept, 2023",
    URL: "https://medium.com/@besocial_27455/deceptive-patterns-the-dark-approach-to-ui-design-c26e22036cce",
    UrlDesc:
      "Deceptive Patterns: The Dark Approach to UI Design ",
    image: IMAGES.JAYANT_BLOG_IMG
  },
  {
    by: "Nishant Kaushik",
    date: "01 Sept, 2023",
    URL: "https://medium.com/@besocial_27455/edge-computing-fcc5f938bb3b",
    UrlDesc:
      "EDGE COMPUTING ",
    image: IMAGES.BLOG_CARD_IMG_5
  },
  {
    by: "Nishant Kaushik",
    date: "25 July, 2023",
    URL: "https://medium.com/@besocial_27455/optimizing-report-project-performance-with-htmxs-lazy-loading-bc182684d250",
    UrlDesc:
      "Optimize your Report Project Performance with HTMX’s Lazy Loading",
    image: IMAGES.BLOG_CARD_IMG_6
  },
  {
    by: "Vikas Raghav",
    date: "16 Mar, 2023",
    URL: "https://medium.com/@besocial_27455/how-to-publish-a-docker-image-to-the-container-registry-on-approval-using-a-github-action-6d2346a4049a",
    UrlDesc:
      "How To: Publish a docker image to the container registry on approval using a GitHub Action",
    image: IMAGES.VIKASH_BLOG_IMG
  },
  {
    by: "Samyakt Jain",
    date: "07 July, 2022",
    URL: "https://medium.com/@besocial_27455/health-insurance-portability-and-accountability-act-hipaa-wingman-partners-e3e0674a2c2",
    UrlDesc:
      "Health Insurance Portability and Accountability Act (HIPAA)",
    image: IMAGES.SAMYAKT_BLOG_IMG
  },
  {
    by: "Mohit Thakur, Mohit Yadav, Kriti Shukla",
    date: "06 Mar, 2022",
    URL: "https://medium.com/@mt000569/simplicity-understanding-of-javascript-dom-86e05744e97a",
    UrlDesc:
      "Simplicity Understanding of Javascript DOM",
    image: IMAGES.MOHIT_BLOG_IMG
  },
  {
    by: "Unique Sharma",
    date: "25 April, 2022",
    URL: "https://medium.com/@unique_sharma/azure-devops-1899a03d0f4b",
    UrlDesc:
      "Azure DevOps",
    image: IMAGES.UNIQUE_BLOG_IMG
  },
  {
    by: "Harpreet Singh Arora",
    date: "28 June, 2021",
    URL: "https://honeyarora11-hs.medium.com/2fa-bypass-securing-methods-7cb28a70c7e1",
    UrlDesc:
      "2FA Bypass Securing methods",
    image: IMAGES.HARPREET_BLOG_IMG
  },
  {
    by: "Kawaldeep Singh",
    date: "23 May, 2021",
    URL: "https://medium.com/@besocial_27455/health-insurance-portability-and-accountability-act-hipaa-wingman-partners-e3e0674a2c2",
    UrlDesc:
      "Health Insurance Portability and Accountability Act (HIPAA)",
    image: IMAGES.KAWALDEEP_BLOG_IMG
  },
  {
    by: "Kekashri Joshi",
    date: "21 May, 2021",
    URL: "https://joshikekashri.medium.com/aws-chatbot-for-sending-notifications-on-slack-regarding-codepipelines-status-18340be837c",
    UrlDesc:
      "AWS Chatbot For Sending Notifications On Slack Regarding CodePipelines Status",
    image: IMAGES.KEKASHRI_BLOG_IMG
  },
  {
    by: "Mukul Srivastava",
    date: "17 March , 2021",
    URL: "https://mukul-sri.medium.com/setting-up-cypress-test-case-on-aws-ecs-containers-2dbf3d31b16e",
    UrlDesc:
      "Setting Up Cypress Test Case On AWS ECS Containers.",
    image: IMAGES.MUKUL_BLOG_ING
  },
];
export const cardAwardsData = [
  {
    URL: "https://businessconnectindia.in/wingman-partners/",
    urlLinkdin: "https://www.linkedin.com/posts/business-connect-magazine_wingman-partners-activity-7037742765538959360-K7HM?utm_source=share&utm_medium=member_desktop",
    UrlDesc: "10 Best CEO’s to Esteemin 2023",
    linkdinImg: IMAGES.LINKDIN_ICON,
    desc:
      "A Visionary Business Leader is Navigating the Untrodden Road to Success with his Brandchild. Ashav Kaushik, a passionate, tenacious, and customer driven visionary leader, is rewriting the generic narrative of an everyday “software firm” with the launch of his brainchild brand, Wingman Partners...",
    image: IMAGES.CERTIFICATE
  },
  {
    URL: "https://www.cioreviewindia.com/magazines/cloud-startups-november-2020/",
    urlLinkdin: "",
    UrlDesc: "CIO Review India",
    //linkdinImg: IMAGES.LINKDIN_ICON,
    desc:
      "Most of the important things in the world have been accomplished by people who have kept on trying when there seemed to be no hope at all. Wingman Partners was started with a simple vision of happy employees and customers and is now being listed in most promising upcoming companies offering enterprise-grade ...",
    image: IMAGES.AWARDS_IMG
  }
];

// For Life at Wingman page
//Celebration card data
type CardCelebrationData = {
  celebrationImage: string;
  key: string;
  width: number;
  height: number;
};
export const cardCelebrationData: CardCelebrationData[] = [

  {
    celebrationImage: IMAGES.CELEBRATION_1,
    key: '1',
    width: 5,
    height: 3,
  },
  {
    celebrationImage: IMAGES.CELEBRATION_2,
    key: '2',
    width: 20,
    height: 10,
  },
  {
    celebrationImage: IMAGES.CELEBRATION_4,
    key: '3',
    width: 3,
    height: 5,
  },
  {
    celebrationImage: IMAGES.CELEBRATION_3,
    key: '4',
    width: 2,
    height: 1,
  },
  {
    celebrationImage: IMAGES.CELEBRATION_6,
    key: '5',
    width: 3,
    height: 5,
  },
  {
    celebrationImage: IMAGES.CELEBRATION_5,
    key: '6',
    width: 20,
    height: 10,
  },
  {
    celebrationImage: IMAGES.CELEBRATION_7,
    key: '7',
    width: 16,
    height: 9,
  },
  {
    celebrationImage: IMAGES.CELEBRATION_8,
    key: '8',
    width: 3,
    height: 4,
  },
  {
    celebrationImage: IMAGES.CELEBRATION_9,
    key: '9',
    width: 2,
    height: 1,
  },
  {
    celebrationImage: IMAGES.CELEBRATION_10,
    key: '10',
    width: 3,
    height: 5,
  },
  {
    celebrationImage: IMAGES.CELEBRATION_11,
    key: '11',
    width: 16,
    height: 9,
  },
  {
    celebrationImage: IMAGES.CELEBRATION_12,
    key: '12',
    width: 16,
    height: 9,
  },
  {
    celebrationImage: IMAGES.CELEBRATION_13,
    key: '13',
    width: 16,
    height: 9,
  },
  {
    celebrationImage: IMAGES.CELEBRATION_14,
    key: '14',
    width: 16,
    height: 9,
  },
  {
    celebrationImage: IMAGES.CELEBRATION_15,
    key: '15',
    width: 16,
    height: 9,
  },
  {
    celebrationImage: IMAGES.CELEBRATION_16,
    key: '16',
    width: 16,
    height: 9,
  },
  {
    celebrationImage: IMAGES.CELEBRATION_17,
    key: '17',
    width: 16,
    height: 9,
  },
  {
    celebrationImage: IMAGES.CELEBRATION_18,
    key: '18',
    width: 16,
    height: 9,
  },
  {
    celebrationImage: IMAGES.CELEBRATION_19,
    key: '19',
    width: 16,
    height: 9,
  },
  {
    celebrationImage: IMAGES.CELEBRATION_20,
    key: '20',
    width: 16,
    height: 9,
  },
  {
    celebrationImage: IMAGES.CELEBRATION_21,
    key: '21',
    width: 16,
    height: 9,
  },
  {
    celebrationImage: IMAGES.CELEBRATION_22,
    key: '22',
    width: 16,
    height: 9,
  },
  {
    celebrationImage: IMAGES.CELEBRATION_23,
    key: '23',
    width: 16,
    height: 9,
  },

  
];
export const lightboxData = cardCelebrationData.map((item) => ({
  src: item.celebrationImage,
  alt: item.key,
  width: 1920,
  height: 1040,
}));
//For Rhythm card Data
export const cardRhythmData = [

  {
    celebrationImage: IMAGES.RHYTHM_1,
    key: '1',
    width: 16,
    height: 9,
  },
  {
    celebrationImage: IMAGES.RHYTHM_2,
    key: '2',
    width: 16,
    height: 9,
  },
  {
    celebrationImage: IMAGES.RHYTHM_3,
    key: '3',
    width: 16,
    height: 9,
  },
  {
    celebrationImage: IMAGES.RHYTHM_4,
    key: '4',
    width: 2,
    height: 1,
  },
  {
    celebrationImage: IMAGES.RHYTHM_5,
    key: '5',
    width: 3,
    height: 5,
  },
  {
    celebrationImage: IMAGES.RHYTHM_6,
    key: '6',
    width: 16,
    height: 9,
  },
  {
    celebrationImage: IMAGES.RHYTHM_7,
    key: '7',
    width: 4,
    height: 8,
  },
  {
    celebrationImage: IMAGES.RHYTHM_8,
    key: '8',
    width: 16,
    height: 9,
  },
  {
    celebrationImage: IMAGES.RHYTHM_9,
    key: '9',
    width: 2,
    height: 1,
  },
  {
    celebrationImage: IMAGES.RHYTHM_10,
    key: '10',
    width: 3,
    height: 5,
  },
  {
    celebrationImage: IMAGES.RHYTHM_11,
    key: '11',
    width: 16,
    height: 9,
  },
  {
    celebrationImage: IMAGES.RHYTHM_12,
    key: '12',
    width: 16,
    height: 9,
  },
  {
    celebrationImage: IMAGES.RHYTHM_13,
    key: '13',
    width: 16,
    height: 9,
  },
  {
    celebrationImage: IMAGES.RHYTHM_14,
    key: '14',
    width: 16,
    height: 9,
  },
  {
    celebrationImage: IMAGES.RHYTHM_15,
    key: '15',
    width: 16,
    height: 9,
  },
  {
    celebrationImage: IMAGES.RHYTHM_16,
    key: '16',
    width: 16,
    height: 9,
  },
];
export const lightboxData1 = cardRhythmData.map((item) => ({
  src: item.celebrationImage,
  alt: item.key,
  width: 1920,
  height: 1040,
}));
//mobile view culture section data
export const cultureMvData = [
  {
    headerName: "People Centric Culture",
    cultureImage: IMAGES.CULTURE_PEOPLE_MOB,
    culturePara: "Our people are our top priority. We invest in their growth and skill development, fostering a safe and creative work environment. "
  },
  {
    headerName: "Curiosity & Innovation",
    cultureImage: IMAGES.CULTURE_INNOVATION_MOB,
    culturePara: "We approach challenges with curiosity, encouraging a mindset of continuous learning, critical thinking, and creative problem-solving."
  },
  {
    headerName: "Build For Tomorrow",
    cultureImage: IMAGES.CULTURE_BUILDING_MOB,
    culturePara: "We are dedicated to creating a meaningful future. We resolve problems creatively, accept risks, and embrace experimentation for long-term success."
  },
  {
    headerName: "Unified Family & Growth",
    cultureImage: IMAGES.CULTURE_FAMILY_MOB,
    culturePara: "We operate as one family, respecting and caring for each other. Our growth involves teamwork, responsibility, and a commitment to delivering excellence."
  },
];

//Google Map link
export const googleMapLink = 'https://www.google.com/maps/dir/28.600958,77.3439278/eccospahre+wingman+partners/@28.6029797,77.3213146,13z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x390cef0900cd7d65:0xfb53d8bb94645502!2m2!1d77.381364!2d28.607251?entry=ttu';

// API Link
export const CONTACT_API_URL = 'https://49ojpdiu4a.execute-api.us-east-2.amazonaws.com/v1/handlingSESMail';

//CONTACT FORM REGEX

export const MAX_MESSAGE_LENGTH = 1000;

export const isValidName = (name: string) => {
  // Define a regular expression pattern for a basic URL
  const pattern = /^[a-zA-Z0-9]+(([',. -][a-zA-Z0-9 ])?[a-zA-Z0-9]*)*$/;

  // Use the test method to check if the URL matches the pattern
  return pattern.test(name);
};

export const isValidEmail = (email: string) => {
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  return pattern.test(email);
}

export const isValidPhone = (phone: string) => {
  const pattern = /^(?=\D*\d)(?=\S{5,15}$)\+?\d{1,4}[-.\s]?\(?\d{1,}\)?[-.\s]?\d{1,}[-.\s]?\d{1,}[-.\s]?\d{1,}$/;

  return pattern.test(phone);
}

export const SOCIAL_LOGIN_ENDPOINT = {
  INSTAGRAM: 'https://www.instagram.com/wingmanpartners/',
  LINKEDIN: 'https://www.linkedin.com/company/wingmanpartners/?viewAsMember=true',
  FACEBOOK: 'https://www.facebook.com/wingmanpartners'
}

// Success Stories

export const networkMgt = {
  imgWeb: IMAGES.NETWORK_WEB,
  imgMob: IMAGES.NETWORK_MOB,
  overview: 'Our client, a cutting-edge Network Management Solution, required a reliable and efficient partner to ensure the seamless functioning of its services. Their Cloud-based Service engine and Web Portal demanded a robust infrastructure and vigilant monitoring to guarantee optimal performance.',
  challenges: [
    { heading: 'Complex Cloud Environment: ', desc: ['Operating in a sophisticated cloud environment with four enterprise-level environments on AWS required adherence to all AWS standard best practices.'], pointer: false },
    { heading: 'Feature Development and Automation: ', desc: ['Continuous development of new features, proof of concepts (PoCs), and comprehensive scripting and automation.'], pointer: false },
    { heading: '24x7 Monitoring and Security: ', desc: ['The nature of the services demanded vigilant 24x7 monitoring, alarms, and alerts.  Additionally, security was a top priority, requiring regular vulnerability identification, and security audits.'], pointer: false }
  ],
  services: [
    { heading: 'Infrastructure Excellence', desc: ['Wingman Partners maintained four enterprise-level environments on AWS.', 'Implemented and adhered to all AWS standard best practices for a secure and efficient cloud environment.'], pointer: true },
    { heading: 'Innovative Development Support', desc: ['Collaborated seamlessly with our team.', 'Contributed to new feature development and conducted proof of concepts to enhance the Network Management System\'s capabilities.'], pointer: true },
    { heading: 'Automation Expertise', desc: ['Leveraged CloudFormation Template, Node.js, and Python scripting.', 'Facilitated scripting and automation for efficiency and minimized manual intervention.'], pointer: true },
    { heading: 'Vigilant Monitoring', desc: ['Implemented a robust monitoring system using Kibana, AWS CloudWatch, and Slack.', 'Enabled 24x7 monitoring, immediate alarms, and alerts for prompt issue resolution.'], pointer: true },
    { heading: 'Cost Optimization', desc: ['Actively monitored and provided suggestions for cost optimization.', 'Ensured optimal utilization of resources.'], pointer: true },
    { heading: 'Security Fortification', desc: ['Conducted weekly vulnerability identification and quarterly security audits.', 'Managed security tools like Sonarqube and CPP check to fortify the cloud infrastructure.'], pointer: true },
  ],
  results: []
}

export const technology = {
  imgWeb: IMAGES.TECHNOLOGY_WEB,
  imgMob: IMAGES.TECHNOLOGY_MOB,
  overview: 'A global technology company that provides the world\'s most valuable brands with innovative merchandising, security, and IoT solutions. By integrating their hardware and software solutions they seamlessly protect and promote their customers\' most valuable assets, leading to improved operations and an enhanced user experience.',
  challenges: [
    { heading: '', desc: ['Executing as an extended engineering arm.', 'Running multiple projects in parallel with the company.', 'Providing and implementing solutions for the deployment of Docker. containerized services across different cloud and native environments, each with unique use cases.', 'Ramping up their existing legacy systems.', 'Reverse engineering and rearchitecting existing products.', 'Acquiring domain knowledge.', 'Architecting and designing new products to face out legacy products.', 'Supporting critical infrastructure solution requirements.', 'Supporting partner clients and deployments.'], pointer: true },
  ],
  services: [
    { heading: 'Infrastructure as Code (IaC): Transforming Operations for Efficiency and Flexibility: ', desc: ['Implemented IaC using Terraform, Shell Scripts, PowerShell scripts, efficient and automated infrastructure management.'], pointer: false },
    { heading: 'Deployment Strategy: ', desc: ['Captured requirements to plan and automate deployment and rollback strategies, minimizing operational disruptions for Cloud & Airgapped env.'], pointer: false },
    { heading: 'Repository and Image Management: ', desc: ['Implemented robust repository branch and Docker image management, enhancing version control and deployment consistency.'], pointer: false },
    { heading: 'Dockerized Application Implementation: ', desc: ['Successfully implemented Dockerized applications (with high availability) and automated processes for an air-gapped environment, ensuring seamless functionality.'], pointer: false },
    { heading: 'Migration to Kubernetes: ', desc: ['Executed the migration of an application from a traditional virtual machine (VM) environment to Kubernetes (k8s) with high availability, optimizing scalability and resource utilization.'], pointer: false },
    { heading: 'Multi-Tenant Architecture on K8s: ', desc: ['Designed and deployed a multi-tenant architecture on Kubernetes, catering to diverse user needs and enhancing system flexibility.'], pointer: false },
    { heading: 'Hybrid Mobile App Development: ', desc: ['Developed a hybrid mobile app with hardware integration, providing enhanced user experience and extending the reach of their technological solutions.'], pointer: false },
    { heading: 'Integration Development: ', desc: ['Developed Lenel integration with existing products and ensured certification, fostering interoperability and expanding the product ecosystem.'], pointer: false },
  ],
  results: [{ heading: '', desc: ['Streamlined and automated deployment processes, minimizing downtime and optimizing resource utilization.', 'Enhanced version control and repository management, ensuring a consistent and reliable software delivery pipeline.', 'Accomplished a seamless migration of a production-running application to Kubernetes, enhancing both scalability and high availability.', 'Strategically planned and deployed an air-gapped architecture for a significant client, ensuring frequent releases without compromising security.', 'Managing a substantial customer account for InVue, successfully orchestrated the migration of their Silo Environment from traditional virtual machines (VM) to Kubernetes (K8s). Our responsibility extends to overseeing their deployments and implementing timely vulnerability fixes, underscoring our commitment to maintaining a secure and resilient infrastructure for our valued clients.', 'Developed hybrid mobile apps, expanding their technological reach and improving user engagement.', 'Developed Mobile App HDK.', 'Critical infrastructure was built.', 'Facilitated seamless integration with Lenel, further enriching the product ecosystem.', 'Support team was extended for any query or problem raised. '], pointer: true }]
}
export const hospitality = {
  imgWeb: IMAGES.HOSPITALITY_STORY,
  imgMob: IMAGES.MOB_HOSPITALITY,
  overview: 'A Hotels Corporation is an American multinational hospitality giant that manages and franchises luxury and business hotels, resorts, and vacation properties across the world.',
  challenges: [
    { heading: '', desc:['The project encompassed UI Designing and Development, intricate integration with their backend, and the setup of User Acceptance Testing (UAT) environments. Additionally, the migration of production environments from other vendors posed a unique set of challenges.'], pointer: false },
  ],
  services: [
    { heading: 'Server Management: ', desc: ['We provided Annual Maintenance Contracts (AMC) for multiple servers, ensuring the seamless operation of 15+ production websites.'], pointer: false },
    { heading: 'Server Migration: ', desc: ['Facilitated server migration for cost optimization, ensuring optimal resource utilization and enhanced efficiency.'], pointer: false },
    { heading: 'Application Containerization: ', desc: ['Implemented application containerization, streamlining deployment processes and enhancing scalability.'], pointer: false },
    { heading: 'UAT Environment Setups: ', desc: ['Established new UAT environments with Continuous Integration/Continuous Deployment (CI/CD) enabled from Gitlab to Hetzner Cloud servers, ensuring a robust testing ecosystem.'], pointer: false },
    { heading: 'Server Security Management: ', desc: ['Ensured the security of servers, implementing comprehensive measures to protect against potential threats and vulnerabilities.'], pointer: false },
    { heading: 'Design Review: ', desc: ['Continuously review and update website design, incorporating user feedback and staying current with design trends.'], pointer: false },
    { heading: 'Website Development: ', desc: ['Delivering cutting-edge website development services for the hospitality industry, seamlessly integrating booking systems and personalized features.'], pointer: false },
    { heading: 'Optimizing Quality Assurance: ', desc: ['Delivering comprehensive QA optimization solutions to enhance website performance and ensure seamless user experiences.'], pointer: false },
  ],
  results: [{ heading: '', desc: ['Successful AMC management for multiple servers, ensuring the uninterrupted operation of critical production websites.', 'Efficient server migration, optimizing costs and enhancing resource utilization for improved performance.', 'Application containerization streamlined deployment processes, promoting scalability and flexibility.', 'Seamless establishment of new UAT environments with CI/CD capabilities, enhancing testing efficiency.', 'Robust server security management, safeguarding critical data and systems against potential threats.'], pointer: true }]
}
export const healthcare = {
  imgWeb: IMAGES.HEALTHCARE_WEB,
  imgMob: IMAGES.MOB_HEALTHCARE,
  overview: 'A rigorous, scientifically validated platform that collects continuous streams of data with precision and accuracy. This raw data stream is then turned into informative and interpretable features that are associated with and predictive of a patient’s behavioral phenotypes.',
  challenges: [
    { heading: '', desc:['The complexity of handling continuous collection of user’s motion data based on mobile with precision and accuracy and at the same time determining the activity of the User based on the data collected. And that Activity can be used to track of user’s health and recovery journey and many more in the healthcare sector.  presented a unique set of challenges. Our partner needed a strategic partner to implement robust solutions for data processing, cost optimization, and deployment automation.'], pointer: false },
  ],
  services: [
    { heading: 'Collect Continuous Motion data from the User’s Mobile and Upload to the Server: ', desc: ['Collect Motion data such as gyroscope and accelerometer from the user’s mobile in the background and send the data/logs to File Storage (In Our case it was AWS S3).', 'Also able to collect the data in offline mode and upload the data to File Storage S3 when users get online.'], pointer: true },
    { heading: 'Implement ML to Predict the User’s Activity: ', desc: ['Facilitated server migration for cost optimization, ensuring optimal resource utilization and enhanced efficiency.'], pointer: true },
    { heading: 'Cost Optimization, Monitoring, Scalability, and Security: ', desc: ['Conducted a comprehensive analysis of the existing infrastructure, providing cost optimization strategies, and implemented monitoring solutions for enhanced efficiency.','Storing Motion data on AWS S3 and not storing it in the database saves lots of cost for the Client.', 'Also to make the App and Web work fast, also reduce the load on the server by redirecting the File Uploading directly from the Mobile App to AWS S3, and that too in a very secure and authenticating way.', 'Every Service on AWS is protected to make the system more secure.', 'Private Database to make data more secure.', 'Also, all Personal Information of Customers is encrypted on the Database level to secure our customer details.'], pointer: true },
    { heading: 'Deploy Zappa Application to Lambda: ', desc: ['Build Admin Panel to view the analytics of user’s data and deployed it on AWS Lambda through Zappa, optimizing the scalability and performance of the platform.', 'Attaching it with API Gateway so that to make it accessible to Frontend Admin Panel.'], pointer: true },
    { heading: 'Configure RDS as a Database: ', desc: ['Configured Amazon Relational Database Service (RDS) as the database, ensuring secure and efficient data storage and retrieval.'], pointer: true },
    { heading: 'Automate Deployments through Code Pipeline: ', desc: ['Implemented automation of deployments through AWS Code Pipeline, streamlining the release process and ensuring consistent and reliable updates.'], pointer: true },
  ],
  results: [
    { heading: 'Smarter Data Management:', desc: ['We built a robust system to gather and understand continuous motion data from users, giving us deeper insights into their behaviors and health trends.'], pointer: true },
    { heading: 'Predictive Insights:', desc: ['By using machine learning, we can now predict user activities accurately, empowering us to provide proactive healthcare support.'], pointer: true },
    { heading: 'Saving Costs:', desc: ['We optimized our data storage strategy, leveraging AWS services like S3, which helped us save significant costs while maintaining efficiency.'], pointer: true },
    { heading: 'Heightened Security:', desc: ['Our focus on security included VPC protection, encryption of sensitive data, and a private database, ensuring our users information remains safe.'], pointer: true },
    { heading: 'Effortless Updates:', desc: ['Through automated deployment with AWS Code Pipeline, we streamlined our update process, making sure our platform stays up-to-date without hassle. Additionally, deploying our admin analytics panel via Lambda and Zappa enhanced scalability and performance.'], pointer: true },
  ]
}
export const transportation = {
  imgWeb: IMAGES.TRANSPORT_WEB,
  imgMob: IMAGES.MOB_TRANSPORT,
  overview: 'An upcoming startup that is revolutionizing the rideshare community by offering an economical option to connect riders and drivers. Their unique models bring fresh perspectives to the rideshare landscape.',
  challenges: [
    { heading: '', desc:['As an emerging startup, they faced challenges in application development and algorithm design. They sought a strategic partner to provide technical expertise, guidance, and support in realizing their innovative rideshare models.'], pointer: false },
  ],
  services: [
    { heading: 'Proof of Concept (PoC) on Google Cloud Platform:  ', desc: ['Conducted a thorough PoC on the Google Cloud Platform to validate the feasibility and efficiency of their rideshare models. '], pointer: true },
    { heading: 'Proposed Architecture with High Availability (HA): ', desc: ['Designed and proposed a robust architecture with high availability and best practices tailored to their specific requirements, ensuring scalability and reliability. '], pointer: true },
    { heading: 'Patent Documentation and Filing: ', desc: ['Provided crucial support in preparing patent documentation, helping them safeguard their innovative ideas and models. '], pointer: true },
    { heading: 'Design and Development of mobile apps ', desc: ['Crafting innovative mobile experiences through cutting-edge design and development. '], pointer: true },
  ],
  results: [
    { heading: '', desc: ['Successful PoC on the Google Cloud Platform, validating the viability and potential of their rideshare models. '], pointer: true },
    { heading: '', desc: ['Implementation of a resilient architecture with high availability, meeting their specific requirements and ensuring scalability. '], pointer: true },
    { heading: '', desc: ['Strategic support in patent documentation, empowering them to protect their innovative ideas and establish a strong foundation for future growth. '], pointer: true },
  ]
}

export const techspaces = {
  imgWeb: IMAGES.TECH_WEB,
  imgMob: IMAGES.MOB_TECH,
  overview: 'The company emerged as a trailblazer in the Indian commercial space sector, offering a tech-enabled network of flexible workspaces. Their platform streamlines processes, provides quick approvals, and offers a comprehensive dashboard for businesses to manage deals efficiently.',
  challenges: [
    { heading: '', desc:['They sought to optimize its present deployed infrastructure and enhance overall platform performance. The need for quick approvals, efficient deal management, and code optimization were crucial aspects that required expert attention.'], pointer: false },
  ],
  services: [
    { heading: 'Reverse Engineering and Bottleneck Identification: ', desc: ['Conducted a thorough reverse engineering analysis of the present deployed infrastructure, identifying bottlenecks affecting performance.'], pointer: true },
    { heading: 'Infrastructure as Code (IaC) Using CloudFormation: ', desc: ['Created Infrastructure as Code (IaC) using CloudFormation for the proposed architecture, ensuring scalability, consistency and flexibility. '], pointer: true },
    { heading: 'Stress/Load Testing Execution: ', desc: ["Executed comprehensive stress and load testing to validate the platform's robustness and performance under varying conditions."], pointer: true },
    { heading: 'Cost Optimization Suggestions: ', desc: ['Provided right-sizing instances, optimizing storage usage, or switching to more cost-effective service tiers, infrastructure for cost optimization, offering strategic suggestions to enhance budget efficiency. '], pointer: true },
    { heading: 'Continuous Monitoring Suggestions: ', desc: ['Implemented continuous monitoring of the infrastructure using tools like Grafana, Prometheus, and Loki, to implement comprehensive monitoring and alerting systems to track resource utilization memory and performance metrics. '], pointer: true },
    { heading: 'Design Review: ', desc: ['Created design for Backend, Website and Mobile design, incorporating customer feedback and staying current with design trends.'], pointer: true },
    { heading: 'Mobile Development: ', desc: ['Created a mobile app from scratch, meeting all coding and industry standards. '], pointer: true },
    { heading: 'Backend Development: ', desc: ['Created backend module from scratch, with reusable Boilerplate.'], pointer: true },

  ],
  results: [
    { heading: '', desc: ['Successful identification and elimination of bottlenecks in the deployed infrastructure, enhancing overall performance. '], pointer: true },
    { heading: '', desc: ['Implementation of Infrastructure as Code (IaC) using CloudFormation, ensuring scalability and flexibility in the proposed architecture. '], pointer: true },
    { heading: '', desc: ["Rigorous stress and load testing, validating the platform's robustness and performance under varying conditions."], pointer: true },
    { heading: '', desc: ["Continuous monitoring and strategic suggestions for cost optimization, ensuring efficient budget utilization. "], pointer: true },
    { heading: '', desc: ["Created a feature design document, demonstrated it to clients, and incorporated all requirements and feedback. "], pointer: true },
    { heading: '', desc: ["Developed a microservice module from scratch for user authentication and authorization using passport strategies. "], pointer: true },
    { heading: '', desc: ["Developed a microservice module from scratch for File Management Service (FMS)."], pointer: true },
    { heading: '', desc: ["Developed a microservice module from scratch for Inventory Management Service. "], pointer: true },
    { heading: '', desc: ["Developed a backend microservice template with 100% reusability. "], pointer: true },
    { heading: '', desc: ["Adding unit test cases at the controller and service levels."], pointer: true },

  ]
}

export const empcommunity = {
  imgWeb: IMAGES.EMP_CARD,
  imgMob: IMAGES.MOB_EMP,
  overview: 'Our partner is a dynamic social platform designed to accelerate work-life happiness. With a focus on connecting job seekers, particularly in the blue-collar and entry-level workforce, the platform combines digital and physical ecosystems to foster community engagement and employment connections. ',
  challenges: [
    { heading: '', desc:['The unique challenge for our partner was to maintain a seamless digital platform that caters to the diverse linguistic and professional needs of its user base. Additionally, ensuring 24x7 monitoring and incorporating new features were crucial aspects that required strategic attention. '], pointer: false },
  ],
  services: [
    { heading: 'Maintenance of AWS Environments: ', desc: ['Maintained three environments on AWS cloud, adhering to all standard best practices to ensure optimal performance and security. '], pointer: true },
    { heading: 'New Feature Development-related PoCs: ', desc: ['Conducted Proof of Concepts (PoCs) for new feature development, ensuring that innovative ideas were thoroughly tested and seamlessly integrated into the platform. '], pointer: true },
    { heading: 'Scripting and Automation: ', desc: ["Implemented scripting and automation using CloudFormation templates, Node.js, and Python scripting to enhance operational efficiency and reduce manual intervention. "], pointer: true },
    { heading: '24x7 Monitoring with Kibana, AWS CloudWatch, and Slack: ', desc: ['Ensured continuous monitoring, alarms, and alerts using Kibana, AWS CloudWatch, and Slack, providing real-time insights into platform performance. '], pointer: true },
    { heading: 'Multilingual App and Website Development: ', desc: ['Constructed a website as well as an application for the platform in Hindi and English to consider the linguistic range of the user base. '], pointer: true },

  ],
  results: [
    { heading: '', desc: ['Two-way WhatsApp conversation has been successfully implemented. It is possible for the jobseeker and employer to register and get employment alerts too. '], pointer: true },
    { heading: '', desc: ['Added AWS lambda functions to send scheduled job notification to JobSeeker and Employer on WhatsApp and Email. '], pointer: true },
    { heading: '', desc: ["Successfully Integrated IVR (Interactive Voice Response) for jobseeker and employer registration. "], pointer: true },
    { heading: '', desc: ["Integrated chatbot seamlessly which handles general FAQs while doubling as a platform for job seekers and employers to register on our Job Portal. "], pointer: true },
    { heading: '', desc: ["By incorporating Google reCAPTCHA into the registration process, we helped verify users securely, mitigating any potential risks. "], pointer: true },
    { heading: '', desc: ["Integrated Twilio for sending SMS notification to shortlisted job seekers. "], pointer: true },
    { heading: '', desc: ["We empowered job seekers by allowing users to enrich their profiles with comprehensive details and effortlessly download their CVs, streamlining the job application process. "], pointer: true },
    { heading: '', desc: ["We stood out with video resumes, allowing users to elevate their applications with a personalized touch, showcasing their experiences and skills effectively. "], pointer: true },
    { heading: '', desc: ["We helped them build a vibrant community by introducing a dedicated Community Tab where users could stay updated on upcoming events, job openings, and exciting features, with detailed information available through provided URL links. "], pointer: true },
    { heading: '', desc: ["We facilitated job sharing by empowering users to spread the word about exciting job opportunities via popular platforms like Facebook, WhatsApp, email, and LinkedIn, fostering a wider reach and engagement. "], pointer: true },
    { heading: '', desc: ["Consistent maintenance of AWS environments, ensuring optimal performance and adherence to security best practices. "], pointer: true },
    { heading: '', desc: ["Successful execution of PoCs for new feature development, fostering innovation and continuous improvement. "], pointer: true },
    { heading: '', desc: ["Implementation of scripting and automation, enhancing operational efficiency and reducing manual workload. "], pointer: true },
    { heading: '', desc: ["Robust 24x7 monitoring with Kibana, AWS CloudWatch, and Slack, providing real-time insights and ensuring platform stability. "], pointer: true },
    { heading: '', desc: ["Development of a multilingual app and website addressing the linguistic diversity of the user base. "], pointer: true },
 
  ]
}

export const kitchendining = {
  imgWeb: IMAGES.KITCHEN_CARD,
  imgMob: IMAGES.MOB_KITCHEN,
  overview: 'Our partner, the largest F&B marketplace in the South Asia Region, serves as a pivotal platform showcasing a diverse array of dining products, processing techniques, and packaging technologies. Through innovative matchmaking programs and internationally hosted buyer events, the platform facilitates connections between buyers and brands, fostering relationships and strengthening global connections. ',
  challenges: [
    { heading: '', desc:['The platform faced the challenge of managing three distinct user types – exhibitors, buyers, and visitors – each with unique needs. Additionally, facilitating efficient product listing and filtering, buyer-exhibitor networking, and incorporating real-time communication features presented intricate challenges. '], pointer: false },
  ],
  services: [
    { heading: 'User-Centric App Design: ', desc: ['Tailored the app to cater to three distinct user types – exhibitors, buyers, and visitors, ensuring a personalized and user-friendly experience. '], pointer: true },
    { heading: 'Product Listing and Filtering: ', desc: ['Implemented robust features for maintaining product listings and efficient product filtering, enhancing the overall discoverability of showcased F&B products. '], pointer: true },
    { heading: 'Buyer-Exhibitor Networking: ', desc: ["Facilitated seamless networking between buyers and exhibitors through a Meeting Scheduler and Messenger, promoting efficient business interactions. "], pointer: true },
    { heading: 'Push Notifications for Meetings and Messages: ', desc: ['Implemented push notifications for every meeting and message, ensuring timely communication and enhancing user engagement. '], pointer: true },
    { heading: 'Delegate Management for Exhibitors: ', desc: ['Provided exhibitors with facilities to add delegates, offering a comprehensive solution for managing representatives and enhancing business opportunities. '], pointer: true },

  ],
  results: [
    { heading: '', desc: ['User-centric app design, catering to the unique needs of exhibitors, buyers, and visitors.'], pointer: true },
    { heading: '', desc: ['Efficient product listing and filtering, improving the discoverability of showcased F&B products. '], pointer: true },
    { heading: '', desc: ["Seamless buyer-exhibitor networking through a Meeting Scheduler and Messenger, facilitating business interactions. "], pointer: true },
    { heading: '', desc: ["Implementation of push notifications, ensuring timely communication for scheduled meetings and messages. "], pointer: true },
    { heading: '', desc: ["Delegate management for exhibitors, offering a streamlined solution for managing representatives. "], pointer: true },
    { heading: '', desc: ["Develop an intuitive search and filtering system that enables users to easily find relevant products. "], pointer: true },
  ]
}


export const ngo = {
  imgWeb: IMAGES.NGO_CARD,
  imgMob: IMAGES.MOB_NGO,
  overview: "The Foundation, a non-government organization (NGO), is dedicated to the preservation of nature and the improvement of living conditions for the less fortunate, operating on a no-profit-no-loss basis. Their mission includes promising impoverished communities' better lifestyles with dignity. The foundation places a primary focus on promoting awareness, particularly among school children and the youth.",
  challenges: [
    { heading: '', desc:['In our partnership with the Foundation, a critical milestone was the launch of their website, a vital platform for disseminating information, fostering engagement, and amplifying their mission. The challenge was to seamlessly guide them through the process of going live with their website, ensuring a user-friendly interface and active effective communication of their initiatives.'], pointer: false },
  ],
  services: [
    { heading: 'Professional Online Presence: ', desc: ["Successfully launched a professional website that effectively communicated the Foundation's mission, initiatives, and impact, enhancing their online presence."], pointer: true },
    { heading: 'User-Friendly Interface: ', desc: ["Developed an intuitive and user-friendly interface, facilitating easy navigation for visitors interested in learning about the Foundation's work and contributing to their cause."], pointer: true },
    { heading: 'Content Accessibility: ', desc: ["Integrated a CMS that empowered the Foundation to update and manage content seamlessly, keeping the website's information current and relevant."], pointer: true },
    { heading: 'Responsive Design: ', desc: ['Ensured the website was responsive, providing a consistent and engaging experience for users across different devices and platforms. '], pointer: true },
    { heading: 'Empowered Team: ', desc: ["Provided comprehensive training and ongoing support, empowering the Foundation's team to confidently manage and update their website, fostering self-sufficiency.", "Provisioning an admin panel for the NGO team to constantly add active communication about the group and its activities to their website. "], pointer: true },
  ],
  results: [{heading:'', desc: [""], pointer: false}]
}