import React, { useEffect } from 'react';
import './style.scss';
import FooterServices from '../FooterServices';
import Footer from 'src/components/footer';
import CardsServices from '../CardsServices';
import { softwareIntegration } from 'src/shared/utils/string';
import { Link } from 'react-router-dom';
import { scrollToTop } from 'src/shared/utils/appConstant';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Import the desired effect styles
import backgroundImage from '../../../../shared/assets/images/software-integration.jpg';
import { pageRoutes } from 'src/shared/utils/pageRoutes.constant';

const SoftwareIntegration = () => {

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const target = entry.target as HTMLDivElement;
            const imageUrl = target.getAttribute('data-background-image');
            if (imageUrl) {
              target.style.backgroundImage = `url(${imageUrl})`;
            }
            observer.unobserve(target);
          }
        });
      },
    );

    document.querySelectorAll('[data-background-image]').forEach((element) => {
      observer.observe(element);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className='page-container-SoftwareIntegration'>
      <div className='image-container' data-background-image={backgroundImage}>
        <LazyLoadImage
          alt="product-background"
          effect="blur"
          wrapperClassName="background-image-placeholder"
        />
        <div className='text-container drop-in-2'>
          <p className='heading'>Software Integration</p>
        </div>
        <p className='breadcrumb position-abs drop-in-3'>
          <Link className='breadcrumb-link' to={pageRoutes.Home} onClick={scrollToTop}>Home </Link>
          <span className="breadcrumb-separator"> / </span>
          <Link className='breadcrumb-link' to={pageRoutes.Services}>Services</Link>
          <span className="breadcrumb-separator"> / </span>
          <Link className='breadcrumb-link' to={pageRoutes.SoftwareEngineering} onClick={scrollToTop}>Software Engineering</Link>
          <span className="breadcrumb-separator"> / </span>
          <Link className='breadcrumb-link fw-light' to={pageRoutes.SoftwareIntegration}>Software Integration</Link>
        </p>
        <div className='red-bg-box'></div>
      </div>

      <CardsServices cardDataServices={softwareIntegration} />
      <FooterServices />
      <Footer />
    </div>
  )
}

export default SoftwareIntegration