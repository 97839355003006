import React, { useState } from 'react';
import { Modal as MuiModal } from '@mui/material';
import './style.scss';
import { IMAGES } from 'src/shared/utils/appConstant';

interface CardProps {
  index: number;
  frontImage: string;
  frontContent: string;
  backImage: string;
  backHeader: string;
  backContent: string;
}
const PopModal: React.FC<CardProps> = ({
  index,
  frontImage,
  frontContent,
  backImage,
  backHeader,
  backContent, }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className='modal position-rel'>
      Modal
      <div className="modal-face modal-front position-abs img-size" onClick={handleOpen}>
        <img src={frontImage} alt="b" />
        <p className='modal-text'>{frontContent}</p>
      </div>
      <MuiModal
        open={open}
        onClose={handleClose}
        aria-labelledby="title"
        aria-describedby="content"
      >
        <div className="modal-face modal-back position-abs img-size " onClick={handleClose}>
          <img className="modal-img" src={IMAGES.CARD_BG} alt="" />
          <div className="overlay-text-card position-abs">
            <p className='back-header' id='title'>{backHeader}</p>
            <p className='back-content' id='content'>{backContent}</p>
          </div>
        </div>
      </MuiModal>
    </div>
  );
};

export default PopModal;