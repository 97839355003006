import React from 'react';
import Breadcrumb from '../Breadcrumb';
import './style.scss';
import CardsServices from 'src/views/SoftwareEngineering/components/CardsServices';
import { projectManagement } from 'src/shared/utils/string';
import FooterServices from 'src/views/SoftwareEngineering/components/FooterServices';
import Footer from 'src/components/footer';
import { Link } from 'react-router-dom';

const ProjectManagement = () => {
  return (
    <div className="page-container-project">
        <div className='image-container'>
          <div className='text-container drop-in-2'>
            <p className='heading'>Project Management</p>
          </div>
            <Link to="/project-management" className='no-decoration'><Breadcrumb text={"Project Management"} /></Link>  
            <div className='red-bg-box'></div>
        </div>

        <CardsServices cardDataServices={projectManagement} />
        <FooterServices />
          <Footer />
    </div>
  )
}

export default ProjectManagement