import React from 'react';
import './style.scss';
import CardsServices from 'src/views/SoftwareEngineering/components/CardsServices';
import { cardDataDevopsOperations } from 'src/shared/utils/string';
import FooterServices from 'src/views/SoftwareEngineering/components/FooterServices';
import Footer from 'src/components/footer';
import BreadcrumbDevOps from '../BreadcrumbDevOps';
import { Link } from 'react-router-dom';

const DevopsOperations = () => {
  return (
    <div className="page-container-devops-operations">
        <div className='image-container'>
          <div className='text-container drop-in-2'>
            <p className='heading'>DevOps Operations </p>
          </div>
            <Link to="/devops-operations" className='no-decoration '><BreadcrumbDevOps text={"DevOps Operations"} /></Link>  
            <div className='red-bg-box'></div>
        </div>

        <CardsServices cardDataServices={cardDataDevopsOperations} />
        <FooterServices />
        <Footer />
    </div>
  )
}

export default DevopsOperations