import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import {
  IMAGES,
  scrollToTop,
  techstack,
  carouselTechOptions,
} from "src/shared/utils/appConstant";
import "bootstrap/dist/css/bootstrap.css";
import Footer from "src/components/footer";
import HomePageFooter from "src/views/home/components/Footer";
import { cardDataTrakwa } from "src/shared/utils/string";
import { images } from "src/shared/utils/appConstant";
import { cardTrakwa } from "src/shared/utils/string";
import "animate.css";
import "animate.css/animate.min.css";
import { pageRoutes } from "src/shared/utils/pageRoutes.constant";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

interface Props {
  cardDataTrakwa: {
    card: string;
    heading: string;
    subPoints: string[];
  }[];
}
interface PropsSolution {
  cardSolution: {
    card: string;
    heading: string;
    subPoints: string[];
  }[];
}
// eslint-disable-next-line
interface PropTrakwa {
  trakwaLast: {
    name: string;
    url: string;
  }[];
}
const CardSolutionsTrakwa = ({ cardDataTrakwa }: Props) => {
  return (
    <div className="main-container-solution-trakwa" id="card-solution">
      {cardDataTrakwa.map((value, index) => (
        <div className="card-solution-trakwa" key={index}>
          <div className="services-card-heading">
            <h5 className="card-trakwa-h5">{value.card}</h5>
          </div>
          <div className="trakwa-point">
            <p className="cad-trakwa-p">
              {value.heading}{" "}
              <span className="card-trakwa-subpoints">{value.subPoints}</span>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

const CardInsideSolution = ({ cardSolution }: PropsSolution) => {
  return (
    <div className="main-container-solutions-trakwa row" id="card-solutions">
      {cardSolution.map((value, index) => (
        <>
          <div className="card-solutions-trakwa" key={index}>
            <div
              className={`vert-line-solutions-trakwa vert-trakwa-${index}`}
            ></div>
            <div className="trakwa-card-heading">
              <p>{value.card}</p>
              <h5>{value.heading}</h5>
              {value.subPoints.map((points, subIndex) => (
                <p
                  key={subIndex}
                  dangerouslySetInnerHTML={{ __html: points }}
                />
              ))}
            </div>
          </div>
        </>
      ))}
    </div>
  );
};
const Solutions = () => {
  return (
    <div className="trakwatulz-container">
      <div className="trakwatulz-image-container image-container position-rel">
        <div className="trakwa-heading-desc display-none drop-in-2">
          <p>
            Effortlessly centralize documents, set timely reminders, and enjoy
            seamless accessibility for unmatched organizational efficiency.
          </p>
        </div>
        <div className="breadcrumb position-abs drop-in-2">
          <Link
            to={pageRoutes.Home}
            onClick={scrollToTop}
            className="breadcrumb-trakwa"
          >
            Home
          </Link>
          <span className="breadcrumb-separator breadcrumb-trakwa"> / </span>
          <Link
            to={pageRoutes.Solutions}
            onClick={scrollToTop}
            className="breadcrumb-trakwa"
          >
            Solutions
          </Link>
          <span className="breadcrumb-separator breadcrumb-trakwa"> / </span>
          <p className="fw-light">
            <Link
              to={pageRoutes.Trakwa}
              onClick={scrollToTop}
              className="breadcrumb-trakwa"
            >
              Trakwa
            </Link>
          </p>
        </div>
      </div>
      <div className="trakwa-content-container ">
        <div className="trakwa-upper-section-heading drop-in-2">
          <img src={images.Trakwa} alt="icon" />
        </div>
        <div className="content-heading">
          <p>
            Effortlessly manage documents, expiration dates, product invoices,
            and warranties in one place with Trakwa. Simplify your life and stay
            organized with our centralized solution.
          </p>
        </div>

        <div className="trakwa-main-content row ">
          <div className="up-content row">
            <div className="cv-mob-bg position-abs display-toggle">
              <img
                className="position-abs"
                src={IMAGES.DOT_CIRCLES}
                alt="dot"
              />
            </div>
            <div className="para-content col-md-6 animate__animated animate__fadeInLeft animate__delay-1s">
              <h1>
                What is <br /> Trakwa ?
              </h1>
              <div className="dashboard-trakwa-mob display-toggle">
                <img src={images.TRAKWA_APP} alt="dashboard-trakwa" />
              </div>
              <p>
                Your destination for seamless document management and
                hassle-free product tracking. Bid farewell to the tedious task
                of hunting down crucial documents or fretting over overlooked
                warranty expirations. With Trakwa at your service, enjoy the
                ease and efficiency of simplified document organization and
                timely product monitoring. Experience peace of mind knowing that
                Trakwa has you covered every step of the way.
              </p>
            </div>
            <div className="mid-content col-md-6 animate__animated animate__fadeInRight animate__delay-1s">
              <div className="dashboard-trakwa-img display-none">
                <img src={images.TRAKWA_APP} alt="trakwa-app" />
              </div>
            </div>
          </div>

          <div className="center-content-trakwa ">
            <h2>
              How Trakwa transforms{" "}
              <span className="color-text-trakwa">document</span> &{" "}
              <span className="color-text-trakwa">product tracking</span>{" "}
            </h2>
          </div>
          <div className="card-solution-points">
            <CardInsideSolution cardSolution={cardDataTrakwa} />
          </div>
          <div className="trakwa-role row">
            <h3>
              Simplified <span className="color-text-trakwa">3-Step</span> Login
              Process 
            </h3>
            <p>
              Trakwa is intuitively designed for ease of use. Follow these three
              simple steps to get started:
            </p>
            <div className="dashboard-trakwa-mob display-toggle">
              <img
                src={images.TRAKWA_SIMPLIFIED}
                alt="dashboard-trakwa"
                className="app-trakwa"
              />
            </div>
            <div className="trakwa-para-content col-md-6 animate__animated animate__fadeInLeft animate__delay-1s">
              <div className="trakwa-p">
                <CardSolutionsTrakwa cardDataTrakwa={cardTrakwa} />
              </div>
            </div>
            <div className="trakwa-mid-content col-md-6 animate__animated animate__fadeInRight animate__delay-1s">
              <div className="app-trakwa-img display-none">
                <img src={images.TRAKWA_SIMPLIFIED} alt="trakwa-app" />
              </div>
            </div>
          </div>
          <div className="trakwa-techstack row">
            <h4>
              {" "}
              <span className="color-text-trakwa"> Technology </span> Stack{" "}
            </h4>
            <p>
              Trakwa leverages cutting-edge technology for seamless functioning.
            </p>
          </div>
          <div className="lower-div">
            <div className="technologies">
              <div className="images-Tech">
                {techstack.map((trakwaLast, index) => (
                  <div key={index} className="image-wrapper">
                    <LazyLoadImage
                      className="hover-effect"
                      effect="blur"
                      src={trakwaLast.url}
                      alt="technology"
                    />
                    <div className="text-overlay">{trakwaLast.name}</div>
                  </div>
                ))}
              </div>
              <div className="owl-carousel-mob">
                <OwlCarousel {...carouselTechOptions}>
                  {techstack.map((URL) => (
                    <div>
                      <img alt="" src={URL.url} />
                    </div>
                  ))}
                </OwlCarousel>
              </div>
            </div>
          </div>
          <div className="last-trakwa">
            <p>
              Experience the simplicity and efficiency of Trakwa – the
              all-in-one solution for document organization and product
              tracking. Sign up today and take control of your documents and
              warranties like never before!
            </p>
          </div>
        </div>
      </div>
      <HomePageFooter page={Solutions} />
      <Footer />
      <div></div>
    </div>
  );
};
export default Solutions;
