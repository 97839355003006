import React from 'react';
import './style.scss';
import CardsServices from 'src/views/SoftwareEngineering/components/CardsServices';
import { cardDataDevopsAuto } from 'src/shared/utils/string';
import FooterServices from 'src/views/SoftwareEngineering/components/FooterServices';
import Footer from 'src/components/footer';
import { Link } from 'react-router-dom';
import BreadcrumbDevOps from '../BreadcrumbDevOps';

const DevOpsAuto = () => {
  return (
    <div className="page-container-cloud-automation">
        <div className='image-container'>
          <div className='text-container drop-in-2'>
          <p className='heading'>DevOps Automation </p>
          </div>
            <Link to="/devops-automation" className='no-decoration '><BreadcrumbDevOps text={"DevOps Automation"} /></Link>  
            <div className='red-bg-box'></div>
        </div>

        <CardsServices cardDataServices={cardDataDevopsAuto} />
        <FooterServices />
        <Footer />
    </div>
  )
}

export default DevOpsAuto