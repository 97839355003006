import React from 'react';
import './style.scss';
import { hospitality } from 'src/shared/utils/string';
import StoryDescription from '../StoryDescription';
import Footer from 'src/components/footer';
import { Link } from 'react-router-dom';
import { scrollToTop } from 'src/shared/utils/appConstant';
import { pageRoutes } from 'src/shared/utils/pageRoutes.constant';

const Hospitality = () => {


  return (
    <div className='page-container-hospitality'>
      <div className='image-container'>
        <p className='heading'>Hospitality</p>
        <p className='breadcrumb'>
          <Link className='breadcrumb-link' to={pageRoutes.Home} onClick={scrollToTop}>Home </Link>
          <span className="breadcrumb-separator"> / </span>
          <Link className='breadcrumb-link' to={pageRoutes.SuccessStories} onClick={scrollToTop}>Success Stories</Link>
          <span className="breadcrumb-separator"> / </span>
          <Link className='breadcrumb-link fw-light' to={pageRoutes.Hospitality} onClick={scrollToTop}>Hospitality</Link>

        </p>
        <div className='red-bg-box'></div>
      </div>
      <StoryDescription storyData={hospitality} />
      <Footer />
    </div>
  )
}

export default Hospitality