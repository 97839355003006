import React from 'react';
import './style.scss';
import { ngo} from 'src/shared/utils/string';
import StoryDescription from '../StoryDescription';
import Footer from 'src/components/footer';
import { Link } from 'react-router-dom';
import { scrollToTop } from 'src/shared/utils/appConstant';
import { pageRoutes } from 'src/shared/utils/pageRoutes.constant';

const Ngo = () => {
  return (
    <div className='page-container-ngo'>
      <div className='image-container'>
        <p className='heading'>Non-Governmental Organization</p>
        <p className='breadcrumb'>
          <Link className='breadcrumb-link' to={pageRoutes.Home} onClick={scrollToTop}>Home </Link>
          <span className="breadcrumb-separator"> / </span>
          <Link className='breadcrumb-link' to= {pageRoutes.SuccessStories} onClick={scrollToTop}>Success Stories</Link>
          <span className="breadcrumb-separator"> / </span>
          <Link className='breadcrumb-link fw-light' to={pageRoutes.NonGovOrg} onClick={scrollToTop}>Non-Governmental Organization</Link>
        </p>
        <div className='red-bg-box'></div>
      </div>
      <StoryDescription storyData={ngo} />
      <Footer />
    </div>
  )
}

export default Ngo