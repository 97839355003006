import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import "bootstrap/dist/css/bootstrap.css";
import Footer from "src/components/footer";
import { cardAwardsData } from "src/shared/utils/string";
import { scrollToTop } from "src/shared/utils/appConstant";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { pageRoutes } from "src/shared/utils/pageRoutes.constant";

const Awards = () => {

  return (
    <div>
      <div className="award-container">
        <div className="awards-image-container image-container position-rel">
          <div className="text-container">
            <p className="heading drop-in">Awards</p>
            <p className="heading-desc display-block display-none drop-in-2">
              Dive into Wingman Partners award-winning journey, where our
              commitment to excellence and industry leadership is celebrated.
            </p>
          </div>
          <div className="breadcrumb position-abs drop-in-2">
            <Link to={pageRoutes.Home} onClick={scrollToTop}>Home</Link>
            <span className="breadcrumb-separator"> / </span>
            <p className="breadcrumb-hover fw-light">
              <Link to={pageRoutes.Awards}>Awards</Link>
            </p>
          </div>
          <div className="red-bg-box"></div>
        </div>
        <div className="awards-card-container">
          <div className="awards-celebrated-heading">
            <p>Our Celebrated Achievements</p>
          </div>
          <div className="row awards-card-row">
            {cardAwardsData.map((value, index) => (
              <div
                key={index}
                className="col-md-4 awards-card-col"
              >
                <LazyLoadImage
                  className='card-img-top awards-card-img'
                  src={value.image}
                  alt='Awards'
                />
                <div className="card-body awards-card-body">
                  <div className="awards-card-content">
                    <div className="awards-card-heading-desc">
                      <span className="URL-desc">{value.UrlDesc}</span>
                      <a href={value.urlLinkdin} target="_blank" rel="noreferrer" className="URL-desc">
                        <img src={value.linkdinImg} alt="" />
                      </a>
                    </div>
                    <p className="award-cards-desc">{value.desc}</p>
                  </div>
                </div>
                <span className="read-btn-blog">
                  <span>
                    <a href={value.URL} target="_blank" rel="noreferrer" className="effect hover-effect">
                      READ NOW
                      <FontAwesomeIcon icon={faArrowRight} className="arrow-icon" />
                    </a>
                  </span>
                </span>
              </div>
            ))}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Awards;
