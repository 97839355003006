import React, { useEffect, useState } from "react";
import "./style.scss";
import {
  images,
  headerImages,
  partnerImages,
  SEVEN_THOUSAND_CONST
} from "src/shared/utils/appConstant";
import { partnerStat, partnerInfo } from "src/shared/utils/string";
import { headingTestimonals } from "src/shared/utils/string";
import "bootstrap/dist/css/bootstrap.css";
import CarouselTestimonials from "../CarouselTestimonals";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "font-awesome/css/font-awesome.min.css";
import 'animate.css';
import 'animate.css/animate.min.css';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const Testimonials = () => {
  const [currentIndex, setcurrentIndex] = useState(0);
  const [prevButtonEnable, setprevButtonEnable] = useState(false);
  const [nextButtonEnable, setnextButtonEnable] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      // Change the content after every 5 seconds
      setcurrentIndex(prevIndex => (prevIndex + 1) % partnerImages.length);
    }, SEVEN_THOUSAND_CONST);
    

    // Clear the interval when the component is unmounted
    return () => clearInterval(interval);
  }, []);

  // funtion for enabling/disabling button
  useEffect(() => {
    if (currentIndex === 0) {
      setprevButtonEnable(false);
      setnextButtonEnable(true);
    } else if (currentIndex === partnerImages.length - 1) {
      setprevButtonEnable(true);
      setnextButtonEnable(false);
    } else {
      setprevButtonEnable(true);
      setnextButtonEnable(true);
    }
  }, [currentIndex]);

  return (
    <div className="page-container-testimonials">
      {/* heading area */}
      <h2>{headingTestimonals}</h2>

      {/* images below heading */}
      <CarouselTestimonials images={headerImages} />
      <div className="row main-testimonials">
        <div className="col-md-6 content-testimonials">
        <AnimationOnScroll animateIn="animate__fadeInLeft" offset={150} animateOnce= {true}>
          <h2>We Value Your Feedback</h2>
          <h1>Partner's <br/> Feedback</h1>
          <div className="testimonial-mob-bg position-abs display-toggle"></div>
          <img alt="" className="blue-box-Testimonials display-none" src={images.blue_box} />
          <div className="dark-blue-box-Testimonials"></div>
          <div className="red-box-Testimonials"></div>
          <h3>
            We Value Your Feedback
            <img alt="" src={images.circles} />
          </h3>
          {/* buttons for navigating carousel */}
          <div className="testimonial-btn dispaly-toggle">
            <button
              className={`btn rounded-circle btn-lg ${prevButtonEnable ? 'enableButton' : 'disableButton'}`}
              disabled={!prevButtonEnable}
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                style={{ fontSize: "2rem", color: "white" }}
                onClick={() => setcurrentIndex(Math.max(0, currentIndex - 1))}
              />
            </button>
            <button
              className={`btn rounded-circle btn-lg ${
                nextButtonEnable ? "enableButton" : "disableButton"
              }`}
              disabled={!nextButtonEnable}
            >
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ fontSize: "2rem", color: "white" }}
                onClick={() => setcurrentIndex(Math.min(partnerImages.length - 1, currentIndex + 1))}
              />
            </button>
          </div>
          </AnimationOnScroll>
        </div>
        <div className="col-md-6 content-img-testimonials animate__animated animate__fadeInRight">
          {/* content of carousel */}
          <div className="row red-rect-testimonials">
            <img alt="" src={partnerImages[currentIndex]} />
            <img alt="" className='quote-img alt = ""' src={images.quote} />
            <div className="stat-testimonials">{partnerStat[currentIndex]}</div>
            <div className="name-testimonials">
              {partnerInfo[currentIndex].name}
              <div className="designation-testimonials">
                {partnerInfo[currentIndex].desg}
              </div>
            </div>
          </div>
  
          {/* designing purpose */}
        </div>
        {/* arrow button for mobile screen */}
        <div className="Arrows-mob-Testimonials">
          <div className="testimonial-btn display-toggle">
            <button
              className={`btn rounded-circle ${prevButtonEnable ? "enableButton" : "disableButton"}`}
              disabled={!prevButtonEnable}
              onClick={() => setcurrentIndex(Math.max(0, currentIndex - 1))}
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                style={{ fontSize: "2rem", color: "white" }}
              />
            </button>
            <button
              className={`btn rounded-circle ${nextButtonEnable ? "enableButton" : "disableButton"}`}
              disabled={!nextButtonEnable}
              onClick={() => setcurrentIndex(Math.min(partnerImages.length - 1, currentIndex + 1))}
            >
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ fontSize: "2rem", color: "white" }}
              />
            </button>
          </div>
        </div>
      </div>

      {/* Vertical line on the right */}
      <div className="sideBorder"></div>
    </div>
  );
};

export default Testimonials;
